import { UserEmployeeProfilePermissions } from 'services/user-employee-profile-permissions';
import * as yup from 'yup';

const API = 'UserEmployeeProfiles';

export const API_USER_EMPLOYEE_PROFILES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<UserEmployeeProfile, 'appIdentityUserID'>) =>
    `${API}/Patch/${data.appIdentityUserID}`,
  DELETE: (data: Pick<UserEmployeeProfile, 'appIdentityUserID'>) =>
    `${API}/Delete/${data.appIdentityUserID}`,
};

export const schemaUserEmployeeProfile = yup.object({
  firstName: yup.string().required('rule-required').min(2, 'rule-min-length'),
  lastName: yup.string().required('rule-required').min(2, 'rule-min-length'),
  email: yup.string().nullable().required('rule-required').email('rule-email'),
  languageID: yup.string().nullable().required('rule-required'),
  userEmployeeProfilePermissionID: yup.string().nullable().required('rule-required'),
  idNumber: yup.string().nullable().required('rule-required'),
});
export class UserEmployeeProfile implements yup.InferType<typeof schemaUserEmployeeProfile> {
  id!: string;
  isActive = true;
  appIdentityUserID!: string;
  languageID!: string;
  email = '';
  mobilePhone = '';
  firstName = '';
  lastName = '';
  generatePasswordEndDate = '';
  userEmployeeProfilePermissionID!: string;
  idNumber = '';
}

export interface IUserEmployeeProfile extends UserEmployeeProfile {
  userEmployeeProfilePermissions: UserEmployeeProfilePermissions;
}

export interface IDashboardTreatingPharmacistsKPIs extends Pick<UserEmployeeProfile, 'id'> {
  consultSessionsCount: number;
}
