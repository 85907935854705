import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addSeconds } from 'date-fns';
import { UserPharmacistProfile } from 'services/user-pharmacist-profiles';
import { actionAccountLogout } from 'store/auth';
import { convertToDate, DateValue } from 'utils/dates';
import { TIME_CREATING_IMPORT_FROM_EXCEL_SECONDS } from '../../services/cardcom';
import { actionUserPharmacistProfilesGetAllDynamic } from './actions';

interface Filters {
  firstName: string;
  lastName: string;
  idNumber: string;
  email: string;
  mobilePhone: string;
  isActiveOnly: boolean;
}
interface Pagination {
  take: number;
  count: number;
  page: number;
}
interface OrderBy {
  field: string;
  order: 'desc' | 'asc' | null;
}
export interface IGridUserPharmacistProfiles
  extends Pick<
    UserPharmacistProfile,
    | 'id'
    | 'appIdentityUserID'
    | 'isActive'
    | 'fullName'
    | 'idNumber'
    | 'email'
    | 'phoneMobile'
    | 'cityTitle'
    | 'licenseNumber'
    | 'position'
  > {
  workPlaceTitle: string;
}

interface BlockInvoice {
  userPharmacistProfileID: string;
  startDate: DateValue;
}
interface BlockInvoiceDetail {
  userPharmacistProfileInvoiceID: string;
  startDate: DateValue;
  endDate?: DateValue;
  requestCount: number;
}

interface State {
  error: null | Error;
  isLoading: boolean;
  isInit: boolean;
  data: IGridUserPharmacistProfiles[];
  filters: Filters;
  pagination: Pagination;
  orderBy: OrderBy;
  dataProfileInvoiceDetailsLength: number;
  blockInvoices: BlockInvoice[];
  blockInvoicesDetails: BlockInvoiceDetail[];
}

const initState = (): State => {
  return {
    error: null,
    isLoading: false,
    dataProfileInvoiceDetailsLength: 0,
    isInit: false,
    data: [],
    filters: {
      firstName: '',
      lastName: '',
      idNumber: '',
      email: '',
      mobilePhone: '',
      isActiveOnly: true,
    },
    pagination: {
      take: 25,
      count: 0,
      page: 1,
    },
    orderBy: {
      field: 'firstName, lastName',
      order: 'asc',
    },
    blockInvoices: [],
    blockInvoicesDetails: [],
  };
};

const slice = createSlice({
  name: 'USER_PHARMACIST_PROFILES',
  initialState: initState(),
  reducers: {
    actionUserPharmacistProfilesFiltersSet(state, action: PayloadAction<Partial<Filters>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    actionUserPharmacistProfilesFiltersReset(state) {
      state.filters = initState().filters;
    },
    actionUserPharmacistProfilesOrderBySet(state, action: PayloadAction<Partial<OrderBy>>) {
      state.orderBy = { ...state.orderBy, ...action.payload };
    },
    actionDataProfileInvoiceDetailsLengthSet(state, action: PayloadAction<number>) {
      state.dataProfileInvoiceDetailsLength = action.payload;
    },

    actionUserPharmacistProfilesBlock(state, action: PayloadAction<BlockInvoice>) {
      state.blockInvoices = [...state.blockInvoices, action.payload];
    },
    actionUserPharmacistProfilesUnBlock(
      state,
      action: PayloadAction<Pick<BlockInvoice, 'userPharmacistProfileID'>>,
    ) {
      state.blockInvoices = state.blockInvoices.filter(
        (item) => item.userPharmacistProfileID !== action.payload.userPharmacistProfileID,
      );
    },
    actionUserPharmacistProfilesInvoicesDetailsBlock(
      state,
      action: PayloadAction<BlockInvoiceDetail>,
    ) {
      state.blockInvoicesDetails = [...state.blockInvoicesDetails, action.payload];
    },
    actionUpdateUserPharmacistProfilesInvoicesDetailsBlock(
      state,
      action: PayloadAction<Pick<BlockInvoiceDetail, 'userPharmacistProfileInvoiceID'>>,
    ) {
      const updatedBlockInvoicesDetails = [...state.blockInvoicesDetails];
      const foundIndex = updatedBlockInvoicesDetails.findIndex(
        (item) =>
          item.userPharmacistProfileInvoiceID === action.payload.userPharmacistProfileInvoiceID,
      );
      if (foundIndex >= 0) {
        updatedBlockInvoicesDetails[foundIndex] = {
          ...updatedBlockInvoicesDetails[foundIndex],
          startDate: new Date(),
          endDate: addSeconds(convertToDate(new Date()), TIME_CREATING_IMPORT_FROM_EXCEL_SECONDS),
          requestCount: updatedBlockInvoicesDetails[foundIndex].requestCount + 1,
        };
        state.blockInvoicesDetails = [...updatedBlockInvoicesDetails];
      }
    },
    actionUserPharmacistProfilesInvoicesDetailsUnBlock(
      state,
      action: PayloadAction<Pick<BlockInvoiceDetail, 'userPharmacistProfileInvoiceID'>>,
    ) {
      state.blockInvoicesDetails = state.blockInvoicesDetails.filter(
        (item) =>
          item.userPharmacistProfileInvoiceID !== action.payload.userPharmacistProfileInvoiceID,
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionUserPharmacistProfilesGetAllDynamic.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
        state.pagination.page = action.meta.arg ? action.meta.arg.page : state.pagination.page;
        state.pagination.take = action.meta.arg?.take || state.pagination.take;
      })
      .addCase(actionUserPharmacistProfilesGetAllDynamic.fulfilled, (state, action) => {
        const {
          payload: { value, count },
        } = action;
        state.isLoading = false;
        state.isInit = true;
        state.data = value;
        state.pagination.count = count;
      })
      .addCase(actionUserPharmacistProfilesGetAllDynamic.rejected, (state, action) => {
        const { error } = action;
        state.isLoading = false;
        state.error = error;
        state.isInit = true;
      });

    builder.addCase(actionAccountLogout.fulfilled, () => {
      return initState();
    });
  },
});

export const {
  actionUserPharmacistProfilesOrderBySet,
  actionUserPharmacistProfilesFiltersSet,
  actionDataProfileInvoiceDetailsLengthSet,

  actionUserPharmacistProfilesBlock,
  actionUserPharmacistProfilesUnBlock,
  actionUserPharmacistProfilesInvoicesDetailsBlock,
  actionUserPharmacistProfilesInvoicesDetailsUnBlock,
  actionUpdateUserPharmacistProfilesInvoicesDetailsBlock,
} = slice.actions;
export const reducerUserPharmacistProfiles = slice.reducer;
