import * as yup from 'yup';

const API = 'UserPharmacistProfileContacts';

export const API_USER_PHARMACIST_PROFILE_CONTACTS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<UserPharmacistProfileContacts, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<UserPharmacistProfileContacts, 'id'>) => `${API}/Delete/${data.id}`,
};
export const schemaUserPharmacistProfileContacts = yup.object({
  name: yup.string().required('rule-required'),
  email: yup.string().required('rule-required').email('rule-email'),
});

export class UserPharmacistProfileContacts
  implements yup.InferType<typeof schemaUserPharmacistProfileContacts>
{
  id!: string;
  name = '';
  department = '';
  email = '';
  phoneMobile = '';
  phoneWork = '';
  phoneHome = '';
  remarks = '';
  userPharmacistProfileID = '';
}
export interface IUserPharmacistProfileContacts extends UserPharmacistProfileContacts {
  name: string;
  department: string;
  email: string;
  phoneMobile: string;
  phoneWork: string;
  phoneHome: string;
  remarks: string;
  userPharmacistProfileID: string;
}
export interface IGridUserPharmacistProfileContacts
  extends Pick<
    IUserPharmacistProfileContacts,
    'id' | 'name' | 'department' | 'email' | 'phoneMobile' | 'phoneHome' | 'phoneWork' | 'remarks'
  > {}
