import { useCallback } from 'react';
import { FieldRenderProps, useFieldProps } from './use-field-props';

export const useCustomFieldProps = () => {
  const result = useFieldProps();
  return useCallback(
    (renderProps: FieldRenderProps) => {
      const props = result(renderProps);
      return { ...props, helperText: props.helperText === ' ' ? '' : props.helperText };
    },
    [result],
  );
};
