import React from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { Box, IconButton, Stack, StackProps } from '@mui/material';
import clsx from 'clsx';
import { SvgLogoText } from 'components/svg-icons/logo-text';
import LogoUrl from 'images/logo.svg';
import VARS from 'styles/config.scss';
import style from './index.module.scss';

interface Props extends Omit<StackProps, 'classes'> {
  isOpen: boolean;
  onToggle: () => void;
}

export const MenuLogo: React.FC<Props> = ({ isOpen, onToggle, children, className, ...rest }) => {
  return (
    <Stack spacing={2} direction={'row'} {...rest} className={clsx(style.root)}>
      <Box className={clsx(style.icon)}>
        <IconButton color={'inherit'} onClick={onToggle}>
          {isOpen ? <MenuOpenIcon /> : <MenuIcon />}
        </IconButton>
      </Box>
      <Stack className={clsx(style.content)} direction={'row'} spacing={0.8} alignItems={'center'}>
        <Box width={40} height={40} component={'img'} src={LogoUrl} />
        <SvgLogoText sx={{ width: 78, height: 24, color: VARS.colorGreyDark }} />
      </Stack>
    </Stack>
  );
};
