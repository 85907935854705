import { contains, dynamicNamespace, DynamicOrder, equals, mergeFilters } from 'utils/dynamic';
import {
  apiRtk,
  behaviourMoveRows,
  decoratorRank,
  DynamicResult,
  DynamicService,
  transformResponseDynamic,
} from 'utils/service';
import { PatchPartial } from 'utils/types';
import { API_CONSULT_REQUEST_TYPES, ConsultRequestType, IGridConsultRequestType } from './models';

const REVALIDATE_KEY = 'ConsultRequestTypes' as const;

const dynamic = dynamicNamespace<IGridConsultRequestType>();

export * from './models';

export interface IGridConsultRequestTypesParams {
  search: string;
  isActiveOnly: boolean;
  order: DynamicOrder;
  take: number;
  skip: number;
}

class Service extends DynamicService<ConsultRequestType> {
  @decoratorRank('rank')
  async post(data: ConsultRequestType) {
    return super.post(data);
  }
  moveRows = async function (
    newRows: Partial<ConsultRequestType>[],
    oldRows: Partial<ConsultRequestType>[],
  ) {
    return behaviourMoveRows({
      mainField: 'id',
      moveField: 'rank',
      newRows,
      oldRows,
      requestPatch: ServiceConsultRequestType.patch,
    });
  };
}

type ApiModel = ConsultRequestType;

type SourceModel = Pick<
  ApiModel,
  'id' | 'title' | 'disclaimerSubTitle' | 'disclaimerTitle' | 'disclaimerHtml'
>;

export const ServiceConsultRequestType = new Service({
  getAll: API_CONSULT_REQUEST_TYPES.GET_ALL_DYNAMIC,
  post: API_CONSULT_REQUEST_TYPES.POST,
  patch: API_CONSULT_REQUEST_TYPES.PATCH,
  delete: API_CONSULT_REQUEST_TYPES.DELETE,
});

export const apiConsultRequestTypes = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getGridConsultRequestTypes: build.query<
      DynamicResult<IGridConsultRequestType, { count: true }>,
      IGridConsultRequestTypesParams
    >({
      queryFn: async ({ search, isActiveOnly, order, take, skip }) => {
        try {
          const params = {
            filter: mergeFilters(
              dynamic.makeFilter(['title'], search, contains),
              isActiveOnly && dynamic.makeFilter('isActive', true, equals),
            ).join('&&'),
            select: dynamic.select(
              'id',
              'title',
              'isActive',
              'disclaimerTitle',
              'disclaimerSubTitle',
              'disclaimerHtml',
              'rank',
              'rowIndex',
            ),
            orderBy: dynamic.orderBy(order.field, order.order),
            take,
            skip,
            count: true,
          };
          const { data } = await ServiceConsultRequestType.getAllDynamic<
            IGridConsultRequestType,
            typeof params
          >(params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getConsultRequestType: build.query<ApiModel, string>({
      queryFn: async (id) => {
        try {
          const params = {
            select: dynamic.select(
              'id',
              'title',
              'isActive',
              'disclaimerTitle',
              'disclaimerSubTitle',
              'disclaimerHtml',
              'rank',
              'rowIndex',
            ),
          };
          const { data } = await ServiceConsultRequestType.getDynamic(id, params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    getSourceConsultRequestTypes: build.query<SourceModel[], void>({
      query: () => ({
        url: API_CONSULT_REQUEST_TYPES.GET_ALL_DYNAMIC,
        params: {
          select: dynamic.select(
            'id',
            'title',
            'disclaimerTitle',
            'disclaimerSubTitle',
            'disclaimerHtml',
          ),
          filter: mergeFilters(dynamic.makeFilter('isActive', true, equals)).join('&&'),
          orderBy: dynamic.orderBy('rank', 'asc'),
        },
      }),
      providesTags: [{ type: REVALIDATE_KEY }],
      transformResponse: transformResponseDynamic,
    }),
    postConsultRequestType: build.mutation<void, ApiModel>({
      queryFn: async (data) => {
        try {
          await ServiceConsultRequestType.post(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }, { type: REVALIDATE_KEY }],
    }),
    patchConsultRequestType: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceConsultRequestType.patch(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
    deleteConsultRequestType: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceConsultRequestType.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
    moveConsultRequestType: build.mutation<
      void,
      { newRows: Partial<ApiModel>[]; oldRows: Partial<ApiModel>[] }
    >({
      queryFn: async ({ newRows, oldRows }) => {
        try {
          await ServiceConsultRequestType.moveRows(newRows, oldRows);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { newRows }) => [
        { type: REVALIDATE_KEY },
        ...newRows.map((item) => ({ type: REVALIDATE_KEY, id: item.id })),
      ],
    }),
  }),
});
