import React from 'react';

import style from './index.module.scss';

import { Box, Container, Stack, Theme, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import { ButtonGoTo } from 'components/buttons';
import { APP_ROUTES } from 'configs';
import { HEADER_PORTAL_ID } from 'configs/const';
import { usePermissions, useTranslate } from 'hooks';
import { Panel } from 'layouts/private/components/panel';
import { useMatch } from 'react-router-dom';
import { HeaderProfile } from '../header-profile';

interface Classes {
  root: string;
}

interface Props {
  className?: string;
  classes?: Partial<Classes>;
}

export const Header: React.FC<Props> = ({ className, classes }) => {
  const { tp } = useTranslate();
  const isDashboard = useMatch(APP_ROUTES.DASHBOARD.path);
  const isPharmacists = useMatch(APP_ROUTES.PHARMACISTS_LIST.path);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const permissions = usePermissions();
  return (
    <Box className={clsx(style.root, classes?.root, className)}>
      <Container maxWidth={false}>
        <Box className={style.inner}>
          <Box id={HEADER_PORTAL_ID} className={style.title} />
          <Box className={style.wrap}>
            {isMobile && <Panel isResponsive />}
            <Stack direction={'row'} spacing={{ xs: 0.5, sm: 1 }} alignItems={'center'}>
              {!isDashboard && (
                <Box>
                  <ButtonGoTo to={APP_ROUTES.DASHBOARD().config}>{tp('dashboard')}</ButtonGoTo>
                </Box>
              )}
              {!isPharmacists && permissions.isAdmin && (
                <Box>
                  <ButtonGoTo to={APP_ROUTES.PHARMACISTS_LIST().config}>
                    {tp('pharmacists-list')}
                  </ButtonGoTo>
                </Box>
              )}
              <Box pl={{ xs: 0, sm: 1 }}>
                <HeaderProfile />
              </Box>
            </Stack>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
