import {
  AccountBalanceWallet,
  Dashboard,
  PermContactCalendar,
  Settings,
} from '@mui/icons-material';
import ForumIcon from '@mui/icons-material/Forum';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, ClickAwayListener, Drawer, IconButton } from '@mui/material';
import clsx from 'clsx';
import { APP_ROUTES } from 'configs';
import { useOpen, usePermissions, useTranslate } from 'hooks';
import React, { useMemo } from 'react';
import variables from 'styles/config.scss';
import { Languages } from '../languages';
import { MenuLogo } from '../menu-logo';
import { Navigation } from '../navigation';
import style from './index.module.scss';

interface Props {
  isResponsive?: boolean;
}

export const Panel: React.FC<Props> = ({ isResponsive = false }) => {
  const { tp } = useTranslate();

  const permissions = usePermissions();

  const {
    isOpen: isOpenDesktop,
    onToggle: onToggleDesktop,
    onClose: onCloseDesktop,
    onOpen: onOpenDesktop,
  } = useOpen();

  const {
    isOpen: isOpenMobile,
    onToggle: onToggleMobile,
    onClose: onCloseMobile,
    onOpen: onOpenMobile,
  } = useOpen();

  const options = useMemo(() => {
    const optionList = [
      {
        title: tp('dashboard'),
        Icon: <Dashboard color={'inherit'} />,
        to: APP_ROUTES.DASHBOARD().config,
      },
      {
        title: tp('consult-sessions'),
        Icon: <ForumIcon color={'inherit'} />,
        to: APP_ROUTES.CONSULT_SESSIONS().config,
      },
    ];
    return permissions.isAdmin
      ? [
          ...optionList,
          {
            title: tp('pharmacists'),
            Icon: <PermContactCalendar color={'inherit'} />,
            options: [
              {
                title: tp('pharmacists-list'),
                to: APP_ROUTES.PHARMACISTS_LIST().config,
              },
              {
                title: tp('pharmacists-activities'),
                to: APP_ROUTES.PHARMACISTS_ACTIVITIES().config,
              },
              {
                title: tp('yearly-subscription'),
                to: APP_ROUTES.YEARLY_SUBSCRIPTIONS().config,
              },
              {
                title: tp('open-invoices'),
                to: APP_ROUTES.OPEN_INVOICES().config,
              },
            ],
          },
          {
            title: tp('expenses-list'),
            Icon: <AccountBalanceWallet color={'inherit'} />,
            options: [
              {
                title: tp('suppliers'),
                to: APP_ROUTES.SUPPLIERS().config,
              },
              {
                title: tp('invoices'),
                to: APP_ROUTES.INVOICES().config,
              },
            ],
          },
          {
            title: tp('settings'),
            Icon: <Settings color={'inherit'} />,
            options: [
              {
                title: tp('labels'),
                to: APP_ROUTES.LABELS().config,
              },
              {
                title: tp('general-settings'),
                to: APP_ROUTES.SETTINGS_GENERAL().config,
              },
              {
                title: tp('employees'),
                to: APP_ROUTES.USERS().config,
              },
              {
                title: tp('permissions'),
                to: APP_ROUTES.PERMISSIONS().config,
              },
              ...(permissions.isRoleOperation
                ? []
                : [
                    {
                      title: tp('consulted-form-settings'),
                      to: APP_ROUTES.CONSULTED_FORM_SETTINGS().config,
                    },
                  ]),
            ],
          },
        ]
      : optionList;
  }, [tp, permissions.isAdmin, permissions.isRoleOperation]);

  return (
    <>
      {!isResponsive ? (
        <ClickAwayListener onClickAway={onCloseDesktop}>
          <Drawer
            open={isOpenDesktop}
            variant={'permanent'}
            onClose={onCloseDesktop}
            classes={{
              paper: clsx(style.paper, isOpenDesktop && style.paperOpen),
            }}
          >
            <Box className={style.inner}>
              <MenuLogo isOpen={isOpenDesktop} onToggle={onToggleDesktop} />
              <Box>
                <Navigation
                  isMenuOpen={isOpenDesktop}
                  onMenuOpen={onOpenDesktop}
                  onMenuClose={onCloseDesktop}
                  options={options}
                />
              </Box>
              <Languages sx={{ mb: 4 }} />
            </Box>
          </Drawer>
        </ClickAwayListener>
      ) : (
        <>
          <IconButton color={'secondary'} size={'small'} onClick={onOpenMobile}>
            <MenuIcon sx={{ color: variables.colorSecondaryDark }} />
          </IconButton>
          <Drawer
            open={isOpenMobile}
            onClose={onCloseMobile}
            classes={{
              paper: style.paperOpen,
            }}
          >
            <Box className={style.inner}>
              <MenuLogo isOpen={isOpenMobile} onToggle={onToggleMobile} />
              <Box>
                <Navigation
                  isMenuOpen={isOpenMobile}
                  onMenuOpen={onOpenMobile}
                  onMenuClose={onCloseMobile}
                  options={options}
                />
              </Box>
              <Languages />
            </Box>
          </Drawer>
        </>
      )}
    </>
  );
};
