import * as yup from 'yup';

const API = 'Labels';

export const API_LABELS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<Label, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<Label, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface ILabel extends Components.Schemas.Label {}

type SchemaFields = Pick<ILabel, 'title' | 'labelKey' | 'languageID'>;

export const schemaLabels: yup.SchemaOf<SchemaFields> = yup.object({
  title: yup.string().required('rule-required'),
  labelKey: yup.string().required('rule-required'),
  languageID: yup.string().required('rule-required'),
});

export class Label implements yup.InferType<typeof schemaLabels> {
  languageID!: string;
  labelSourceID!: string;
  labelKey = '';
  id!: string;
  title = '';
  isActive = true;
}
