import { FixServerObject } from 'utils/types';
import * as yup from 'yup';

const API = 'MedicalStaffWorkPlaces';

export const API_MEDICAL_STAFF_WORK_PLACES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<MedicalStaffWorkPlace, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<MedicalStaffWorkPlace, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface IMedicalStaffWorkPlace
  extends FixServerObject<
    Components.Schemas.MedicalStaffWorkPlace,
    'id' | 'rowIndex' | 'isOther' | 'title' | 'isActive'
  > {}

export const schemaMedicalStaffWorkPlace = yup.object({
  title: yup.string().required('rule-required'),
});

export class MedicalStaffWorkPlace implements yup.InferType<typeof schemaMedicalStaffWorkPlace> {
  id!: string;
  title = '';
  isActive = true;
  isOther = false;
  rank = 0;
}

export interface IGridMedicalStaffWorkPlace
  extends Pick<
    IMedicalStaffWorkPlace,
    'id' | 'title' | 'rowIndex' | 'isActive' | 'isOther' | 'rank'
  > {}
