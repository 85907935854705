import { BoxProps, SvgIconProps } from '@mui/material';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import { SvgLogo } from 'components/svg-icons/logo';
import React from 'react';
import style from './index.module.scss';

interface Classes {
  root: string;
  icon: string;
}
interface Props extends BoxProps {
  IconProps?: SvgIconProps;
  classes?: Partial<Classes>;
}
export const AppLoading: React.FC<Props> = ({ IconProps, className, classes, ...rest }) => {
  return (
    <Box
      className={clsx(style.root, className, classes?.root)}
      position={'absolute'}
      top={0}
      right={0}
      bottom={0}
      left={0}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      {...rest}
    >
      <SvgLogo
        fontSize={'large'}
        sx={{ fontSize: 100 }}
        {...IconProps}
        className={clsx(style.icon, classes?.icon)}
      />
    </Box>
  );
};
