import { useTranslate } from 'hooks/use-translate';
import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { ToastContent, ToastOptions } from 'react-toastify/dist/types';
import { parseErrorData } from 'utils/service';

export const useNotify = () => {
  const { t } = useTranslate();

  const notifySuccess = toast.success;
  const notifyError = toast.error;

  const onError = useCallback(
    (e: { message?: string }) => {
      const err = parseErrorData(e);
      return notifyError(t(err.message));
    },
    [t, notifyError],
  );
  const onSuccess = useCallback(
    (content?: ToastContent<unknown>, options?: ToastOptions<{}> | undefined) => {
      return notifySuccess(t('success') || content, options);
    },
    [t, notifySuccess],
  );
  return { onError, onSuccess, notifySuccess, notifyError };
};
export const useEffectNotifyError = (e: { message?: string } | undefined | null) => {
  const { onError } = useNotify();

  useEffect(() => {
    if (e) {
      onError(e);
    }
  }, [e, onError]);
};
