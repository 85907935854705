import { IGridUserEmployee } from 'store/user-employees';
import {
  contains,
  decoratorIsNotNullable,
  dynamicNamespace,
  DynamicOrder,
  equals,
  mergeFilters,
} from 'utils/dynamic';
import { apiRtk, decoratorWithFiles, DynamicResult, DynamicService } from 'utils/service';
import { PatchPartial } from 'utils/types';
import {
  API_USER_EMPLOYEE_PROFILES,
  IDashboardTreatingPharmacistsKPIs,
  IUserEmployeeProfile,
  UserEmployeeProfile,
} from './models';

export * from './models';

const dynamic = dynamicNamespace<IUserEmployeeProfile>();

type Model = UserEmployeeProfile;
const REVALIDATE_TAG = 'UserCrmProfiles';

class Service extends DynamicService<Model> {
  @decoratorWithFiles('appIdentityUserID', 'userPhoto')
  async post(data: Model) {
    return super.post(data);
  }
  @decoratorWithFiles('appIdentityUserID', 'userPhoto')
  async patch(data: Partial<Model>) {
    return super.patch(data);
  }
}

export const ServiceUserEmployeeProfiles = new Service({
  mainField: 'appIdentityUserID',
  getAll: API_USER_EMPLOYEE_PROFILES.GET_ALL_DYNAMIC,
  post: API_USER_EMPLOYEE_PROFILES.POST,
  patch: API_USER_EMPLOYEE_PROFILES.PATCH,
  delete: API_USER_EMPLOYEE_PROFILES.DELETE,
});

export interface IGridUserCrmProfilesParams {
  search: string;
  take?: number;
  skip?: number;
  orderBy: DynamicOrder;
  userEmployeeProfilePermissionID: string;
}

export const apiUserEmployeeProfiles = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getDashboardTreatingPharmacistsKPIs: build.query<IDashboardTreatingPharmacistsKPIs[], void>({
      queryFn: async () => {
        try {
          const params = {
            select: dynamic.select('id', 'consultSessions.count() as consultSessionsCount'),
          };
          const {
            data: { value },
          } = await ServiceUserEmployeeProfiles.getAllDynamic<
            IDashboardTreatingPharmacistsKPIs,
            typeof params
          >(params);
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getGridUserProfiles: build.query<
      DynamicResult<IGridUserEmployee, { count: true }>,
      IGridUserCrmProfilesParams
    >({
      queryFn: async ({ userEmployeeProfilePermissionID, search, orderBy, take, skip }) => {
        try {
          const params = {
            filter: mergeFilters(
              dynamic.makeFilter(
                ['firstName', 'lastName', 'email', 'mobilePhone'],
                search,
                decoratorIsNotNullable(contains),
              ),
              dynamic.makeFilter(
                'userEmployeeProfilePermissionID',
                userEmployeeProfilePermissionID,
                decoratorIsNotNullable(equals),
              ),
            ).join('&&'),
            select: dynamic.select(
              'id',
              'appIdentityUserID',
              'firstName',
              'lastName',
              'userEmployeeProfilePermissionID',
              'email',
              'mobilePhone',
              'isActive',
              'userCrmProfilePermission.title as position',
            ),
            orderBy: dynamic.orderBy(orderBy.field, orderBy.order),
            count: true,
            take,
            skip,
          };
          const { data } = await ServiceUserEmployeeProfiles.getAllDynamic<
            IGridUserEmployee,
            typeof params
          >(params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getUserCrmProfileSource: build.query<
      { id: string; title: string }[],
      { appIdentityUserID?: string | boolean }
    >({
      queryFn: async ({ appIdentityUserID }) => {
        try {
          const {
            data: { value },
          } = await ServiceUserEmployeeProfiles.getAllDynamic<{ id: string; title: string }>({
            select: dynamic.select(
              'appIdentityUserID as id',
              '(firstName + " " + lastName).trim() as title',
            ),
            filter: mergeFilters(
              appIdentityUserID &&
                dynamic.makeFilter('appIdentityUserID', appIdentityUserID, equals),
            ).join('&&'),
            orderBy: '(firstName + " " + lastName).trim() asc',
          });
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getUserCrmProfile: build.query<Model, string>({
      queryFn: async (appIdentityUserID) => {
        try {
          const { data } = await ServiceUserEmployeeProfiles.getDynamic(appIdentityUserID);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_TAG, id }],
    }),
    postUserCrmProfile: build.mutation<void, Model>({
      queryFn: async (data) => {
        try {
          await ServiceUserEmployeeProfiles.post(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    patchUserCrmProfile: build.mutation<void, PatchPartial<Model, 'appIdentityUserID'>>({
      queryFn: async (data) => {
        try {
          await ServiceUserEmployeeProfiles.patch(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { appIdentityUserID }) => [
        { type: REVALIDATE_TAG, id: appIdentityUserID },
      ],
    }),
    deleteUserCrmProfile: build.mutation<void, PatchPartial<Model, 'appIdentityUserID'>>({
      queryFn: async (data) => {
        try {
          await ServiceUserEmployeeProfiles.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { appIdentityUserID }) => [
        { type: REVALIDATE_TAG, id: appIdentityUserID },
      ],
    }),
  }),
});
