import { createTypedRoute } from 'modules/typed-routes';

export const APP_ROUTES = {
  DASHBOARD: createTypedRoute('/'),
  PHARMACISTS_LIST: createTypedRoute('/pharmacist/list'),
  PHARMACISTS_ACTIVITIES: createTypedRoute('/pharmacist/activities'),
  YEARLY_SUBSCRIPTIONS: createTypedRoute('/pharmacist/yearly-subscription'),
  OPEN_INVOICES: createTypedRoute('/pharmacist/open-invoices'),
  PROFILE: createTypedRoute('/profile'),
  CONSULT_SESSIONS: createTypedRoute('/consult-sessions'),

  USERS: createTypedRoute('/settings/users'),
  USER_ITEM: createTypedRoute<{ appUserID: string | 'new' }>('/settings/users/:appUserID'),
  PERMISSIONS: createTypedRoute('/settings/permissions'),

  LABELS: createTypedRoute('/settings/labels'),
  LANGUAGES: createTypedRoute('/settings/labels/languages'),

  SETTINGS: createTypedRoute('/settings'),
  SETTINGS_GENERAL: createTypedRoute('/settings/general'),
  NAME_PREFIXES_ENG: createTypedRoute('/settings/general/name-prefixes-eng'),
  NATIONALITIES: createTypedRoute('/settings/general/nationalities'),
  MEMBER_TYPES: createTypedRoute('/settings/general/member-types'),
  NAME_PREFIXES: createTypedRoute('/settings/general/name-prefixes'),
  PAYMENT_TYPES: createTypedRoute('/settings/general/payment-types'),
  REGIONS: createTypedRoute('/settings/general/regions'),
  CITIES: createTypedRoute('/settings/general/cities'),
  WORK_PLACES: createTypedRoute('/settings/general/work-places'),
  WORK_PLACE_TYPES: createTypedRoute('/settings/general/work-place-types'),
  EXTERNAL_CHANNELS: createTypedRoute('/settings/general/external-channels'),
  PAYMENT_ACCOUNTS: createTypedRoute('/settings/general/payment-accounts'),
  INCOME_ACCOUNTS: createTypedRoute('/settings/general/income-accounts'),
  INVOICE_TYPES: createTypedRoute('/settings/general/invoice-types'),

  CONSULTED_FORM_SETTINGS: createTypedRoute('/settings/consulted-form'),
  RIGHTS_EXTRACTION_CLASSIFICATIONS: createTypedRoute(
    '/settings/consulted-form/rights-extraction-classifications',
  ),
  CLINICAL_CLASSIFICATIONS_SUBJECTS: createTypedRoute(
    '/settings/consulted-form/clinical-classification-subjects',
  ),
  LOGISTIC_CLASSIFICATIONS: createTypedRoute('/settings/consulted-form/logistic-classifications'),
  INSURANCE_STATUSES: createTypedRoute('/settings/consulted-form/insurance-statuses'),
  REFERRAL_TYPES: createTypedRoute('/settings/consulted-form/referral-types'),
  MEDICAL_STAFF_OPTIONS: createTypedRoute('/settings/consulted-form/medical-staff-options'),
  MEDICAL_STAFF_WORK_PLACES: createTypedRoute('/settings/consulted-form/medical-staff-work-places'),
  RESPONSIVENESS_TO_TREATMENTS: createTypedRoute(
    '/settings/consulted-form/responsiveness-to-treatments',
  ),
  REFERENCE_SOURCES: createTypedRoute('/settings/consulted-form/reference-sources'),
  GENDERS: createTypedRoute('/settings/consulted-form/genders'),

  INVOICES: createTypedRoute('/expenses-list/invoices'),
  SUPPLIERS: createTypedRoute('/expenses-list/suppliers'),

  LOGIN: createTypedRoute<{ redirect?: string }>('/login'),
  LOGIN_CODE: createTypedRoute<{ email: string; redirect?: string }>('/login/:email'),
};
