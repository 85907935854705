import * as yup from 'yup';

const API = 'CardcomServices';

export const API_CARDCOM_SERVICES = {
  CREATE_PAYMENT_PAGE: `${API}/CreatePaymentPage`,
  CREATE_INVOICE_WITHOUT_PAGE: `${API}/CreateInvoiceWithoutPayment`,
  CREATE_INVOICE_FOR_WIRE_TRANSFER: `${API}/CreateInvoiceForWireTransfer`,
};

export interface ICreatePaymentPage {
  userPharmacistProfileInvoiceID: string;
  successURL: string;
  errorURL: string;
}
export interface ICreateInvoiceWithoutPayment {
  userPharmacistProfileInvoiceID: string;
}

export const schemaCreateInvoiceForWireTransfer = yup.object({
  remarks: yup.string().required('rule-required'),
  paymentDate: yup.string().required('rule-required'),
});

export class CreateInvoiceForWireTransfer
  implements yup.InferType<typeof schemaCreateInvoiceForWireTransfer>
{
  remarks = '';
  paymentDate = new Date().toISOString();
}

export interface ICreateInvoiceForWireTransfer extends CreateInvoiceForWireTransfer {
  userPharmacistProfileInvoiceID: string;
}
export interface ICreateInvoiceWithoutPayment {
  userPharmacistProfileInvoiceID: string;
}

export const TIME_CREATING_INVOICE_PAYMENT_SECONDS = 180;
export const TIME_CREATING_IMPORT_FROM_EXCEL_SECONDS = 300;
