import { Button, ButtonProps, Tooltip } from '@mui/material';
import { useTranslate } from 'hooks';
import React, { useMemo } from 'react';

interface Classes {
  root: string;
}

interface Props extends ButtonProps {
  className?: string;
  classes?: Partial<Classes>;
}

export const ButtonCancel: React.FC<Props & { target?: string }> = ({
  title,
  children,
  ...rest
}) => {
  const { tp } = useTranslate();
  const _title = useMemo(() => {
    if (children === tp('close')) {
      return tp('close-tooltip');
    }
    return tp('cancel-tooltip');
  }, [children, tp]);
  return (
    <Tooltip title={title || _title}>
      <Button color={'secondary'} {...rest}>
        {children || tp('cancel')}
      </Button>
    </Tooltip>
  );
};
