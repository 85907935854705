import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  apiUserPharmacistProfiles,
  IUserPharmacistProfile,
  ServiceUserPharmacistProfiles,
  UserPharmacistProfile,
} from 'services/user-pharmacist-profiles';
import { AppAsyncThunkConfig } from 'store/index';
import { contains, dynamicNamespace, mergeFilters, notEquals } from 'utils/dynamic';
import { parseErrorData } from 'utils/service';
import {
  selectUserPharmacistProfilesFilters,
  selectUserPharmacistProfilesOrderBy,
  selectUserPharmacistProfilesPagination,
} from './selectors';
import { IGridUserPharmacistProfiles } from './slice';

const dynamic = dynamicNamespace<IUserPharmacistProfile>();

export const actionUserPharmacistProfilesGetAllDynamic = createAsyncThunk<
  { value: IGridUserPharmacistProfiles[]; count: number },
  { page: number; take?: number } | void,
  AppAsyncThunkConfig
>(`USER_PHARMACIST_PROFILES/getAllDynamic`, async (payload, { getState }) => {
  try {
    const { firstName, lastName, email, idNumber, mobilePhone, isActiveOnly } =
      selectUserPharmacistProfilesFilters(getState());
    const { take, skip } = selectUserPharmacistProfilesPagination(getState());
    const { field, order } = selectUserPharmacistProfilesOrderBy(getState());

    const params = {
      filter: mergeFilters(
        isActiveOnly && dynamic.makeFilter(['firstName', 'lastName'], '', notEquals),
        dynamic.makeFilter(['firstName'], firstName, contains),
        dynamic.makeFilter(['lastName'], lastName, contains),
        dynamic.makeFilter(['email'], email, contains),
        dynamic.makeFilter(['idNumber'], idNumber, contains),
        dynamic.makeFilter(['phoneMobile'], mobilePhone, contains),
      ).join('&&'),
      select: dynamic.select(
        'id',
        '(firstName + " " + lastName).trim() as fullName',
        'idNumber',
        'email',
        'appIdentityUserID',
        'phoneMobile',
        'city.title as cityTitle',
        'licenseNumber',
        'position',
        'workPlace.title as workPlaceTitle',
        'isActive',
      ),
      orderBy: dynamic.orderBy(field as any, order),
      count: true,
      take,
      skip,
    };
    const { data: result } = await ServiceUserPharmacistProfiles.getAllDynamic<
      IGridUserPharmacistProfiles,
      typeof params
    >(params);

    return result;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
export const actionUserPharmacistProfilesChangeItem = createAsyncThunk<
  void,
  Partial<UserPharmacistProfile>,
  AppAsyncThunkConfig
>(`USER_PHARMACIST_PROFILES/changeItem`, async (data, { dispatch }) => {
  try {
    const { appIdentityUserID } = data;

    if (!appIdentityUserID) {
      throw new Error('appIdentityUserID is required');
    }
    await dispatch(
      apiUserPharmacistProfiles.endpoints.patchUserPharmacistProfile.initiate({
        ...data,
        appIdentityUserID,
      }),
    );
    await dispatch(actionUserPharmacistProfilesGetAllDynamic()).unwrap();
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
