import { base64ToFileStream, ValueFileUploaderFile } from 'utils/file-uploader';
import { getRandomString } from 'utils/other';
import { apiApp } from 'utils/service';
import { API_MEDIA_UPLOADS, MediaUpload } from './models';

export * from './models';

class Service {
  async uploadFile(data: ValueFileUploaderFile) {
    const { name, value } = data;
    const fileName = [getRandomString(20), name].filter(Boolean).join('__');
    const content = base64ToFileStream(value);

    return apiApp.post<Required<MediaUpload>>(API_MEDIA_UPLOADS.UPLOAD_FILE, {
      fileName,
      content,
    });
  }

  async uploadPDF(data: { fileName: string; content: string }) {
    return apiApp.post<Required<MediaUpload>>(API_MEDIA_UPLOADS.UPLOAD_FILE, data);
  }
  async remove(data: Pick<Required<MediaUpload>, 'filePath'>) {
    try {
      await apiApp.put(API_MEDIA_UPLOADS.REMOVE_FILE(data));
    } catch (e) {
      console.warn('Error to remove file');
    }
  }
}

export const ServiceMediaUploads = new Service();
