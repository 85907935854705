import { TranslateMap, TranslateProps } from 'configs';
import { IUserPharmacistProfileContacts } from 'services/user-pharmacist-profile-contacts';
import { IUserPharmacistProfileInvoiceDetails } from 'services/user-pharmacist-profile-invoice-details';
import { IUserPharmacistProfile } from 'services/user-pharmacist-profiles';
import { DateValue } from 'utils/dates';
import { DynamicOrder } from 'utils/dynamic';
import { ArrayType } from 'utils/types';
import * as yup from 'yup';

const API = 'UserPharmacistProfileInvoices';

export const API_USER_PHARMACIST_PROFILE_INVOICES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<UserPharmacistProfileInvoices, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<UserPharmacistProfileInvoices, 'id'>) => `${API}/Delete/${data.id}`,
  SEND_EMAIL: `${API}/SendEmail`,
  VALIDATE_EXCEL: `${API}/ValidateExcel`,
  UPLOAD_BULK_INVOICE_DETAILS: `${API}/UploadBulkInvoiceDetails`,
};
export const schemaUserPharmacistProfileInvoiceSetIgnore = yup.object({
  internalRemarks: yup.string().nullable().required('rule-required'),
});
export class UserPharmacistProfileInvoiceSetIgnore
  implements yup.InferType<typeof schemaUserPharmacistProfileInvoiceSetIgnore>
{
  internalRemarks = '';
}
export const schemaUserPharmacistProfileInvoiceSendEmail = yup.object({
  userPharmacistProfileInvoiceID: yup.string().nullable().required('rule-required'),
  email: yup.string().nullable().required('rule-required'),
});
export class UserPharmacistProfileInvoiceSendEmail
  implements yup.InferType<typeof schemaUserPharmacistProfileInvoiceSendEmail>
{
  email = '';
  userPharmacistProfileInvoiceID = '';
}
export const schemaUserPharmacistProfileInvoices = yup.object({
  invoiceNumber: yup.string().required('rule-required'),
  userPharmacistProfileID: yup.string().required('rule-required'),
  invoiceDate: yup.string().required('rule-required'),
  invoiceTypeID: yup.string().required('rule-required'),
  poNumber: yup.string(),
  remarks: yup.string().nullable().notRequired().max(250, 'rule-max-length'),
});
export class UserPharmacistProfileInvoices
  implements yup.InferType<typeof schemaUserPharmacistProfileInvoices>
{
  id!: string;
  poNumber = '';
  isActive: boolean = true;
  userPharmacistProfileID!: string;
  invoiceNumber = '';
  invoiceDate = new Date().toISOString();
  paymentDate = new Date().toISOString();
  isPaid = false;
  isSent = false;
  invoiceURL = '';
  invoiceTypeID!: string;
  remarks = '';
  ignore = false;
  internalRemarks = '';
  cardRemarks = '';
  userPharmacistProfileContactID? = '';
}
export interface UserPharmacistProfileInvoicesExtended extends UserPharmacistProfileInvoices {
  contactName: string;
}
export interface IUserPharmacistProfileInvoices extends UserPharmacistProfileInvoices {
  userPharmacistProfile: IUserPharmacistProfile;
  userPharmacistProfileInvoiceDetails: IUserPharmacistProfileInvoiceDetails[];
  paymentRequired: boolean;
  'invoiceType.step': string;
  'userPharmacistProfileInvoiceDetails.count()': string;
}
export interface IGridUserPharmacistProfileInvoices
  extends Pick<
    IUserPharmacistProfileInvoices,
    | 'id'
    | 'isActive'
    | 'invoiceDate'
    | 'invoiceNumber'
    | 'paymentDate'
    | 'invoiceURL'
    | 'isPaid'
    | 'isSent'
    | 'invoiceTypeID'
    | 'paymentRequired'
    | 'internalRemarks'
    | 'cardRemarks'
    | 'ignore'
  > {
  userPharmacistProfileID: string;
  'userPharmacistProfileInvoiceDetails.sum(s=>s.amount)': string;
  totalAmount: string;
  invoiceTypeTitle: string;
  step: number;
  userPharmacistProfileInvoiceDetails: IUserPharmacistProfileInvoiceDetails[];
  contactName: IUserPharmacistProfileContacts['name'];
}

export interface ConvertUserPharmacistProfileInvoicesInput {
  invoiceID: string;
  invoiceTypeID: string;
  tp: <T extends keyof TranslateMap>(...params: TranslateProps<T>) => string;
}
export interface CreateReceiptUserPharmacistProfileInvoicesInput {
  invoices: {
    userPharmacistProfileInvoiceDetails: IUserPharmacistProfileInvoiceDetails[];
  }[];
  userPharmacistProfileID: string;
  invoiceTypeID: string;
  cardRemarks: string;
  listIDs: string[];
}
export interface IgnoreAndDuplicateUserPharmacistProfileInvoicesInput {
  invoiceID: string;
}
export interface ConvertUserPharmacistProfileInvoicesGet
  extends Pick<
    IUserPharmacistProfileInvoices,
    | 'isActive'
    | 'userPharmacistProfileID'
    | 'invoiceTypeID'
    | 'invoiceNumber'
    | 'invoiceDate'
    | 'invoiceURL'
    | 'paymentDate'
    | 'isSent'
    | 'isPaid'
    | 'cardRemarks'
    | 'internalRemarks'
    | 'id'
  > {
  invoiceTypeTitle: string;
  userPharmacistProfileInvoiceDetails: Pick<
    ArrayType<IUserPharmacistProfileInvoices['userPharmacistProfileInvoiceDetails']>,
    | 'isActive'
    | 'userPharmacistProfileInvoiceID'
    | 'userPharmacistProfileID'
    | 'paymentTypeID'
    | 'incomeAccountID'
    | 'accountCode'
    | 'quantity'
    | 'discountPercentage'
    | 'discountAmount'
    | 'amount'
    | 'fromDate'
    | 'toDate'
    | 'score'
    | 'status'
    | 'quality'
    | 'duration'
    | 'contribution'
    | 'sector'
    | 'remarks'
  >[];
}

export interface IDashboardPaymentInvoices
  extends Pick<
    IUserPharmacistProfileInvoices,
    'id' | 'paymentDate' | 'invoiceNumber' | 'invoiceURL' | 'userPharmacistProfileID' | 'isPaid'
  > {
  paidBy: IUserPharmacistProfileInvoices['userPharmacistProfile']['fullName'];
  totalAmount: number;
}

export interface IDashboardPaymentInvoicesArgs {
  search: string;
  date: DateValue[];
  orderBy: DynamicOrder;
}

export interface IGridOpenInvoices extends Pick<IUserPharmacistProfileInvoiceDetails, 'id'> {
  invoiceNumber: IUserPharmacistProfileInvoices['invoiceNumber'];
  invoiceDate: IUserPharmacistProfileInvoices['invoiceDate'];
  cardRemarks: IUserPharmacistProfileInvoices['cardRemarks'];
  invoiceURL: IUserPharmacistProfileInvoices['invoiceURL'];
  poNumber: IUserPharmacistProfileInvoices['poNumber'];
  isSent: IUserPharmacistProfileInvoices['isSent'];
  name: IUserPharmacistProfileInvoices['userPharmacistProfile']['fullName'];
  userPharmacistProfileID: string;
  totalAmount: string;
  contactName: IUserPharmacistProfileContacts['name'];
}

export interface IGridOpenInvoicesFilters {
  searchFirstName: string;
  searchLastName: string;
  search: string;
  invoiceNumber: string;
  date: DateValue[];
  isActiveOnly: boolean;
}

export interface IGridOpenInvoicesArgs extends IGridOpenInvoicesFilters {
  take: number;
  skip: number;
  order: DynamicOrder;
}
