import { createAsyncThunk } from '@reduxjs/toolkit';
import { LABEL_SOURCE_ID } from 'services/label-sources';
import { apiLabels, Label, ServiceLabels } from 'services/labels';
import { IGridLabel } from 'store/labels/slice';
import {
  contains,
  decoratorExclude,
  decoratorIsNotNullable,
  dynamicNamespace,
  equals,
  mergeFilters,
} from 'utils/dynamic';
import { parseErrorData } from 'utils/service';
import { AppAsyncThunkConfig } from '../index';
import { selectLabelsFilters, selectLabelsOrderBy, selectLabelsPagination } from './selectors';

const dynamic = dynamicNamespace<Label>();

export const actionLabelsGetDynamic = createAsyncThunk<
  { value: IGridLabel[]; count: number },
  { page: number; take?: number } | void,
  AppAsyncThunkConfig
>(`LABELS/getDynamic`, async (payload, { getState }) => {
  try {
    const { search, languageID } = selectLabelsFilters(getState());
    const { take, skip } = selectLabelsPagination(getState());
    const { field, order } = selectLabelsOrderBy(getState());
    const params = {
      filter: mergeFilters(
        dynamic.makeFilter(['title', 'labelKey'], search, contains),
        dynamic.makeFilter(['languageID'], languageID, decoratorIsNotNullable(equals)),
        dynamic.makeFilter(
          ['labelSourceID'],
          LABEL_SOURCE_ID.CRM,
          decoratorIsNotNullable(decoratorExclude(equals, 'all')),
        ),
      ).join('&&'),
      select: dynamic.select(
        'id',
        'title',
        'labelKey',
        'language.title as languageTitle',
        'isActive',
      ),
      orderBy: dynamic.orderBy(field as any, order),
      count: true,
      take,
      skip,
    };
    const { data: result } = await ServiceLabels.getAllDynamic<IGridLabel, typeof params>(params);
    return result;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionLabelsChangeItem = createAsyncThunk<void, Partial<Label>, AppAsyncThunkConfig>(
  `LABELS/changeItem`,
  async (data, { dispatch }) => {
    try {
      const id = data.id;
      if (!id) {
        throw new Error('id is required');
      }
      await dispatch(apiLabels.endpoints.patchLabel.initiate({ ...data, id })).unwrap();
      await dispatch(actionLabelsGetDynamic()).unwrap();
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);
