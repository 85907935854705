import { contains, dynamicNamespace, equals, mergeFilters } from 'utils/dynamic';
import {
  apiRtk,
  DynamicResult,
  DynamicService,
  transformResponseDynamic,
  transformResponseDynamicItemMaybe,
} from 'utils/service';
import { PatchPartial } from 'utils/types';
import { API_SUPPLIERS, IGridSupplier, ISupplierInvoice, Supplier } from './models';

const REVALIDATE_KEY = 'Suppliers' as const;

const dynamic = dynamicNamespace<IGridSupplier>();

export * from './models';

class Service extends DynamicService<Supplier> {}

type ApiModel = Supplier;

export interface IGridSuppliersParams {
  search: string;
  order: { field: string; order: 'asc' | 'desc' | null };
  take: number;
  skip: number;
}

export const ServiceSuppliers = new Service({
  getAll: API_SUPPLIERS.GET_ALL_DYNAMIC,
  post: API_SUPPLIERS.POST,
  patch: API_SUPPLIERS.PATCH,
  delete: API_SUPPLIERS.DELETE,
});

export const apiSuppliers = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getSupplierInvoice: build.query<ISupplierInvoice, string>({
      query: (supplierID) => ({
        url: API_SUPPLIERS.GET_ALL_DYNAMIC,
        params: {
          select: dynamic.select(
            'invoices.OrderBy(o=>o.invoiceDate).first().invoiceDate as firstInvoice',
            'invoices.OrderByDescending(o=>o.invoiceDate).first().invoiceDate as lastInvoice',
            'invoices.count() as numberOfInvoices',
          ),
          filter: mergeFilters(dynamic.makeFilter('id', supplierID, equals)).join('&&'),
        },
      }),
      transformResponse: transformResponseDynamicItemMaybe,
      providesTags: [{ type: REVALIDATE_KEY }, { type: 'Invoices' }],
    }),
    getSuppliers: build.query<DynamicResult<IGridSupplier, { count: true }>, IGridSuppliersParams>({
      queryFn: async ({ search, order, take, skip }) => {
        try {
          const params = {
            filter: mergeFilters(
              dynamic.makeFilter(
                ['companyName', 'companyNumber', 'phone1', 'phone2', 'phoneMobile', 'email'],
                search,
                contains,
              ),
            ).join('&&'),
            select: dynamic.select(
              'id',
              'companyName',
              'companyNumber',
              'phone1',
              'phone2',
              'phoneMobile',
              'email',
              'invoices.count() as numberOfInvoices',
              'isActive',
            ),
            orderBy: dynamic.orderBy(order.field, order.order),
            take,
            skip,
            count: true,
          };
          const { data } = await ServiceSuppliers.getAllDynamic<IGridSupplier, typeof params>(
            params,
          );
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getSupplier: build.query<ApiModel, string>({
      queryFn: async (id) => {
        try {
          const params = {
            select: dynamic.select(
              'id',
              'companyName',
              'companyNumber',
              'phone1',
              'phone2',
              'phoneMobile',
              'email',
              'address',
              'bankAccountDetails',
            ),
          };
          const { data } = await ServiceSuppliers.getDynamic(id, params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    getSuppliersOption: build.query<IGridSupplier[], void>({
      query: () => ({
        url: API_SUPPLIERS.GET_ALL_DYNAMIC,
        params: {
          filter: mergeFilters(dynamic.makeFilter(['isActive'], true, equals)).join('&&'),
          select: dynamic.select('id', 'companyName as title'),
          orderBy: 'companyName asc',
        },
      }),
      transformResponse: transformResponseDynamic,
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    postSupplier: build.mutation<void, ApiModel>({
      queryFn: async (data) => {
        try {
          await ServiceSuppliers.post(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }, { type: REVALIDATE_KEY }],
    }),
    patchSupplier: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceSuppliers.patch(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
    deleteSupplier: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceSuppliers.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
  }),
});
