import { contains, dynamicNamespace, DynamicOrder, equals, mergeFilters } from 'utils/dynamic';
import { apiRtk, DynamicResult, DynamicService, transformResponseDynamic } from 'utils/service';
import { PatchPartial } from 'utils/types';
import { API_NAME_PREFIXES, IGridNamePrefix, NamePrefix } from './models';

const REVALIDATE_KEY = 'NamePrefixes' as const;

const dynamic = dynamicNamespace<IGridNamePrefix>();

export * from './models';

class Service extends DynamicService<NamePrefix> {}

export interface IGridNamePrefixesParams {
  search: string;
  isActiveOnly: boolean;
  order: DynamicOrder;
  take: number;
  skip: number;
}

type ApiModel = NamePrefix;

export const ServiceNamePrefixes = new Service({
  getAll: API_NAME_PREFIXES.GET_ALL_DYNAMIC,
  post: API_NAME_PREFIXES.POST,
  patch: API_NAME_PREFIXES.PATCH,
  delete: API_NAME_PREFIXES.DELETE,
});

export const apiNamePrefixes = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getGridNamePrefixes: build.query<
      DynamicResult<IGridNamePrefix, { count: true }>,
      IGridNamePrefixesParams
    >({
      queryFn: async ({ search, isActiveOnly, order, take, skip }) => {
        try {
          const params = {
            filter: mergeFilters(
              dynamic.makeFilter(['title'], search, contains),
              isActiveOnly && dynamic.makeFilter('isActive', true, equals),
            ).join('&&'),
            select: dynamic.select(
              'id',
              'rowIndex',
              'title',
              'userPatientProfiles.count() as numberOfPharmacists',
              'isActive',
            ),
            orderBy: dynamic.orderBy(order.field, order.order),
            take,
            skip,
            count: true,
          };
          const { data } = await ServiceNamePrefixes.getAllDynamic<IGridNamePrefix, typeof params>(
            params,
          );
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getNamePrefix: build.query<ApiModel, string>({
      queryFn: async (id) => {
        try {
          const params = {
            select: dynamic.select('id', 'title', 'isActive'),
          };
          const { data } = await ServiceNamePrefixes.getDynamic(id, params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    getNamePrefixesOption: build.query<ApiModel[], void>({
      query: () => ({
        url: API_NAME_PREFIXES.GET_ALL_DYNAMIC,
        params: {
          filter: mergeFilters(dynamic.makeFilter(['isActive'], true, equals)).join('&&'),
          select: dynamic.select('id', 'title'),
          orderBy: 'title',
        },
      }),
      transformResponse: transformResponseDynamic,
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    postNamePrefix: build.mutation<void, ApiModel>({
      queryFn: async (data) => {
        try {
          await ServiceNamePrefixes.post(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }, { type: REVALIDATE_KEY }],
    }),
    patchNamePrefix: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceNamePrefixes.patch(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
    deleteNamePrefix: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceNamePrefixes.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
  }),
});
