import { IPaymentTypeSubjects } from 'services/payment-type-subjects';
import { DynamicOrder } from 'utils/dynamic';
import * as yup from 'yup';

const API = 'PaymentTypes';

export const API_PAYMENT_TYPES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<PaymentType, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<PaymentType, 'id'>) => `${API}/Delete/${data.id}`,
};

export const schemaPaymentTypes = yup.object({
  title: yup.string().required('rule-required'),
  paymentTypeSubjectID: yup.string().required('rule-required'),
  defaultAmount: yup.number().nullable().notRequired(),
});

export class PaymentType implements yup.InferType<typeof schemaPaymentTypes> {
  id!: string;
  title = '';
  isActive = true;
  paymentTypeSubjectID = '';
  defaultAmount = 0;
  showRemarks = false;
}
export interface IPaymentType extends PaymentType {
  userPharmacistProfilePayments: [];
  paymentTypeSubject: IPaymentTypeSubjects;
  rowIndex: string;
}

export interface IGridPaymentType
  extends Pick<
    IPaymentType,
    'id' | 'title' | 'rowIndex' | 'isActive' | 'defaultAmount' | 'showRemarks'
  > {
  numberOfPharmacists: number;
  paymentTypeSubjectTitle: IPaymentType['paymentTypeSubject']['title'];
}
export interface IGridPaymentTypeArgs {
  search: string;
  isActiveOnly: boolean;
  order: DynamicOrder;
  take: number;
  skip: number;
}

export interface IDashboardPaymentLinesArgs {
  search: string;
  orderBy: DynamicOrder;
}
export interface IDashboardPaymentLines extends Pick<IPaymentType, 'id' | 'title'> {
  paymentTypeSubjectTitle: IPaymentType['paymentTypeSubject']['title'];
}
