import React, { useCallback, useEffect, useId, useMemo, useState } from 'react';

import { ExpandMore } from '@mui/icons-material';
import { Collapse, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import clsx from 'clsx';
import { navigationEmitter } from 'layouts/private/emitter';
import { resolvePath, To, useLocation, useNavigate } from 'react-router-dom';
import variables from 'styles/config.scss';
import style from './index.module.scss';

const checkIsActive = (option: Pick<Option, 'to' | 'options'>, pathname: string): boolean => {
  const { to, options } = option;
  const path = to && resolvePath(to).pathname;

  const isActiveCurrent =
    path === pathname || (path && new RegExp(`${path}(/|$)`, 'g').test(pathname));

  if (isActiveCurrent) {
    return true;
  }

  if (options) {
    return options.some((op) => checkIsActive(op, pathname));
  }

  return false;
};

interface Option {
  title: string;
  Icon?: React.ReactNode;
  to?: To;
  options?: Option[];
}

interface Props extends Option {
  isMenuOpen?: boolean;
  onMenuClose?: () => void;
  onMenuOpen?: () => void;
  level: number;
}

export const NavigationItem: React.FC<Props> = ({
  Icon,
  title,
  options,
  to,
  isMenuOpen,
  onMenuOpen,
  onMenuClose,
  level,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const subItems = useMemo(() => {
    return options || [];
  }, [options]);

  const isActive = useMemo(() => {
    return checkIsActive({ to, options }, pathname);
  }, [to, pathname, options]);

  const [isOpen, setIsOpen] = useState(isActive);

  const componentID = useId();

  useEffect(() => {
    const onToggleCollapse = (params: { componentID: string; value: boolean }) => {
      if (params.componentID === componentID) {
        setIsOpen(params.value);
      } else {
        setIsOpen(false);
      }
    };
    if (subItems.length > 0) {
      navigationEmitter.on('onToggleCollapse', onToggleCollapse);
    }
    return () => {
      navigationEmitter.off('onToggleCollapse', onToggleCollapse);
    };
  }, [subItems, componentID]);

  const primary = title;
  const secondary = useMemo(() => {
    return subItems.map(({ title }) => title).join(', ');
  }, [subItems]);

  const onClickButton = useCallback(() => {
    if (to) {
      onMenuClose && onMenuClose();
      navigate(to);
    } else {
      onMenuOpen && onMenuOpen();
      navigationEmitter.emit('onToggleCollapse', {
        componentID,
        value: !isMenuOpen ? true : !isOpen,
      });
    }
  }, [to, navigate, onMenuClose, onMenuOpen, componentID, isOpen, isMenuOpen]);

  const isCollapseOpen = isMenuOpen && isOpen;
  const isInner = level > 1;
  return (
    <>
      <ListItemButton
        disableGutters
        className={clsx(style.item, isInner && style.itemInner)}
        color={'white'}
        onClick={onClickButton}
      >
        <Tooltip title={primary}>
          <ListItemIcon className={clsx(style.itemIcon, isActive && style.itemIconActive)}>
            {Icon}
          </ListItemIcon>
        </Tooltip>

        <ListItemText
          className={clsx(isInner && style.itemTextInner, style.itemText)}
          primary={primary}
          primaryTypographyProps={{
            color: isActive ? 'secondary.dark' : 'texx.primary',
            fontWeight: 400,
            variant: isInner ? 'subtitle2' : 'subtitle1',
          }}
          secondary={secondary}
          secondaryTypographyProps={{
            noWrap: true,
            variant: 'caption',
            fontWeight: 400,
            color: `${variables.colorGrey}`,
            lineHeight: 1.6,
          }}
        />
        {subItems.length > 0 && (
          <ExpandMore className={clsx(style.icon, isOpen && style.iconOpen)} />
        )}
      </ListItemButton>
      {subItems.length > 0 && (
        <Collapse in={isCollapseOpen}>
          {subItems.map((option, i) => {
            return (
              <NavigationItem
                key={i}
                isMenuOpen={isMenuOpen}
                onMenuOpen={onMenuOpen}
                onMenuClose={onMenuClose}
                title={option.title}
                to={option.to}
                level={level + 1}
              />
            );
          })}
        </Collapse>
      )}
    </>
  );
};
