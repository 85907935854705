import { dynamicNamespace, equals, mergeFilters } from 'utils/dynamic';
import { apiRtk, DynamicResult, DynamicService, transformResponseDynamic } from 'utils/service';
import { PatchPartial } from 'utils/types';
import {
  API_CONSULT_SESSION_MEDICATIONS,
  ConsultSessionMedication,
  IGridConsultSessionMedication,
} from './models';

const REVALIDATE_KEY = 'ConsultSessionMedications' as const;

const dynamic = dynamicNamespace<IGridConsultSessionMedication>();

export * from './models';

class Service extends DynamicService<ConsultSessionMedication> {}

export interface IGridConsultSessionMedicationsParams {
  consultSessionID: string;
}

type ApiModel = ConsultSessionMedication;

export const ServiceConsultSessionMedications = new Service({
  getAll: API_CONSULT_SESSION_MEDICATIONS.GET_ALL_DYNAMIC,
  post: API_CONSULT_SESSION_MEDICATIONS.POST,
  patch: API_CONSULT_SESSION_MEDICATIONS.PATCH,
  delete: API_CONSULT_SESSION_MEDICATIONS.DELETE,
});

export const apiConsultSessionMedications = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getGridConsultSessionMedications: build.query<
      DynamicResult<IGridConsultSessionMedication, { count: true }>,
      IGridConsultSessionMedicationsParams
    >({
      queryFn: async ({ consultSessionID }) => {
        try {
          const params = {
            filter: mergeFilters(
              dynamic.makeFilter('consultSessionID', consultSessionID, equals),
            ).join('&&'),
            select: dynamic.select(
              'id',
              'title',
              'responsivenessToTreatment.title as responsivenessToTreatment',
              'diagnostics',
              'remarks',
            ),
            orderBy: dynamic.orderBy('title', 'asc'),
            count: true,
          };
          const { data } = await ServiceConsultSessionMedications.getAllDynamic<
            IGridConsultSessionMedication,
            typeof params
          >(params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getConsultSessionMedications: build.query<
      DynamicResult<IGridConsultSessionMedication, { count: true }>,
      { consultSessionID: string }
    >({
      queryFn: async ({ consultSessionID }) => {
        try {
          const params = {
            filter: mergeFilters(
              dynamic.makeFilter('consultSessionID', consultSessionID, equals),
            ).join('&&'),
            select: dynamic.select(
              'id',
              'title',
              'responsivenessToTreatment.title as responsivenessToTreatment',
              'diagnostics',
              'remarks',
              'responsivenessToTreatmentID',
              'consultSession',
              'consultSessionClassifications',
              'responsivenessToTreatment as responsivenessToTreatmentTest',
              'isActive',
            ),
            count: true,
            orderBy: dynamic.orderBy('rowIndex', 'asc'),
          };
          const { data } = await ServiceConsultSessionMedications.getAllDynamic<
            IGridConsultSessionMedication,
            typeof params
          >(params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getSourceConsultSessionMedication: build.query<{ id: string; title: string }[], void>({
      query: () => ({
        url: API_CONSULT_SESSION_MEDICATIONS.GET_ALL_DYNAMIC,
        params: {
          select: dynamic.select('id', 'title'),
          orderBy: dynamic.orderBy('title', 'asc'),
        },
      }),
      providesTags: [{ type: REVALIDATE_KEY }],
      transformResponse: transformResponseDynamic,
    }),
    getConsultSessionMedication: build.query<ApiModel, string>({
      queryFn: async (id) => {
        try {
          const params = {
            select: dynamic.select(
              'id',
              'title',
              'responsivenessToTreatmentID',
              'diagnostics',
              'remarks',
            ),
          };
          const { data } = await ServiceConsultSessionMedications.getDynamic(id, params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    postConsultSessionMedication: build.mutation<ApiModel, ApiModel>({
      queryFn: async (data) => {
        try {
          return await ServiceConsultSessionMedications.post(data);
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }, { type: REVALIDATE_KEY }],
    }),
    patchConsultSessionMedication: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceConsultSessionMedications.patch(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
    deleteConsultSessionMedication: build.mutation<void, PatchPartial<any, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceConsultSessionMedications.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
  }),
});
