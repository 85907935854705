import { contains, dynamicNamespace, DynamicOrder, equals, mergeFilters } from 'utils/dynamic';
import { apiRtk, DynamicResult, DynamicService, transformResponseDynamic } from 'utils/service';
import { PatchPartial } from 'utils/types';
import { API_PAYMENT_TYPE_SUBJECTS, IGridPaymentTypeSubjects, PaymentTypeSubjects } from './models';

const REVALIDATE_KEY = 'PaymentTypeSubjects' as const;

const dynamic = dynamicNamespace<IGridPaymentTypeSubjects>();

export * from './models';

export interface IGridPaymentTypeSubjectsParams {
  search: string;
  isActiveOnly: boolean;
  order: DynamicOrder;
  take: number;
  skip: number;
}

class Service extends DynamicService<PaymentTypeSubjects> {}

type ApiModel = PaymentTypeSubjects;

export const ServicePaymentTypeSubjects = new Service({
  getAll: API_PAYMENT_TYPE_SUBJECTS.GET_ALL_DYNAMIC,
  post: API_PAYMENT_TYPE_SUBJECTS.POST,
  patch: API_PAYMENT_TYPE_SUBJECTS.PATCH,
  delete: API_PAYMENT_TYPE_SUBJECTS.DELETE,
});

type SourceModel = Pick<ApiModel, 'id' | 'title'>;
export const apiPaymentTypeSubjects = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getSourcePaymentTypeSubjects: build.query<SourceModel[], void>({
      query: () => ({
        url: API_PAYMENT_TYPE_SUBJECTS.GET_ALL_DYNAMIC,
        params: {
          select: dynamic.select('id', 'title'),
          filter: mergeFilters(dynamic.makeFilter('isActive', true, equals)).join('&&'),
        },
      }),
      providesTags: [{ type: REVALIDATE_KEY }],
      transformResponse: transformResponseDynamic,
    }),
    getGridPaymentTypeSubjects: build.query<
      DynamicResult<IGridPaymentTypeSubjects, { count: true }>,
      IGridPaymentTypeSubjectsParams
    >({
      queryFn: async ({ search, order, take, skip, isActiveOnly }) => {
        try {
          const params = {
            filter: mergeFilters(
              dynamic.makeFilter('title', search, contains),
              isActiveOnly && dynamic.makeFilter('isActive', true, equals),
            ).join('&&'),
            select: dynamic.select('id', 'rowIndex', 'title', 'isActive'),
            orderBy: dynamic.orderBy(order.field, order.order),
            take,
            skip,
            count: true,
          };
          const { data } = await ServicePaymentTypeSubjects.getAllDynamic<
            IGridPaymentTypeSubjects,
            typeof params
          >(params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getPaymentTypeSubject: build.query<ApiModel, string>({
      queryFn: async (id) => {
        try {
          const params = {
            select: dynamic.select('id', 'title', 'isActive'),
          };
          const { data } = await ServicePaymentTypeSubjects.getDynamic(id, params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    postPaymentTypeSubject: build.mutation<void, ApiModel>({
      queryFn: async (data) => {
        try {
          await ServicePaymentTypeSubjects.post(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }, { type: REVALIDATE_KEY }],
    }),
    patchPaymentTypeSubject: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServicePaymentTypeSubjects.patch(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
    deletePaymentTypeSubject: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServicePaymentTypeSubjects.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
  }),
});
