import {
  contains,
  dynamicNamespace,
  DynamicOrder,
  equals,
  mergeFilters,
  notEquals,
} from 'utils/dynamic';
import {
  apiRtk,
  DynamicResult,
  DynamicService,
  transformResponseDynamicItemMaybe,
} from 'utils/service';
import { PatchPartial } from 'utils/types';

import { IGridUserPharmacistProfiles } from 'store/user-pharmacist-profiles';
import {
  API_USER_PHARMACIST_PROFILES,
  IUserPharmacistProfile,
  UserPharmacistProfile,
} from './models';

const dynamic = dynamicNamespace<IUserPharmacistProfile>();

const REVALIDATE_TAG = 'UserPharmacistProfiles' as const;

export * from './models';

class Service extends DynamicService<UserPharmacistProfile> {}

export const ServiceUserPharmacistProfiles = new Service({
  mainField: 'appIdentityUserID',
  getAll: API_USER_PHARMACIST_PROFILES.GET_ALL_DYNAMIC,
  post: API_USER_PHARMACIST_PROFILES.POST,
  patch: API_USER_PHARMACIST_PROFILES.PATCH,
  delete: API_USER_PHARMACIST_PROFILES.DELETE,
});

interface SourceModel extends Pick<IUserPharmacistProfile, 'firstName' | 'lastName' | 'idNumber'> {
  id: IUserPharmacistProfile['appIdentityUserID'];
  title: IUserPharmacistProfile['fullName'];
}

export interface IGridUserPharmacistProfilesParams {
  isActiveOnly: boolean;
  take?: number;
  skip?: number;
  orderBy: DynamicOrder;
  firstName: string;
  lastName: string;
  email: string;
  idNumber: string;
  mobilePhone: string;
}

export const apiUserPharmacistProfiles = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getGridUserPharmacistProfiles: build.query<
      DynamicResult<IGridUserPharmacistProfiles, { count: true }>,
      IGridUserPharmacistProfilesParams
    >({
      queryFn: async ({
        isActiveOnly,
        firstName,
        lastName,
        email,
        idNumber,
        orderBy,
        mobilePhone,
        take,
        skip,
      }) => {
        try {
          const params = {
            filter: mergeFilters(
              isActiveOnly && dynamic.makeFilter(['firstName', 'lastName'], '', notEquals),
              dynamic.makeFilter(['firstName'], firstName, contains),
              dynamic.makeFilter(['lastName'], lastName, contains),
              dynamic.makeFilter(['email'], email, contains),
              dynamic.makeFilter(['idNumber'], idNumber, contains),
              dynamic.makeFilter(['mobilePhone'], mobilePhone, contains),
            ).join('&&'),
            select: dynamic.select(
              'id',
              '(firstName + " " + lastName).trim() as fullName',
              'idNumber',
              'email',
              'appIdentityUserID',
              'phoneMobile',
              'city.title as cityTitle',
              'licenseNumber',
              'position',
              'workPlace.title as workPlaceTitle',
            ),
            orderBy: dynamic.orderBy(orderBy.field, orderBy.order),
            count: true,
            take,
            skip,
          };
          const { data } = await ServiceUserPharmacistProfiles.getAllDynamic<
            IGridUserPharmacistProfiles,
            typeof params
          >(params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getUserPharmacistProfilesName: build.query<SourceModel[], void>({
      queryFn: async () => {
        try {
          const {
            data: { value },
          } = await ServiceUserPharmacistProfiles.getAllDynamic<SourceModel>({
            select: dynamic.select(
              'appIdentityUserID as id',
              '(firstName + " " + lastName).trim() as title',
              'firstName',
              'lastName',
              'idNumber',
            ),
            filter: mergeFilters(
              dynamic.makeFilter(['firstName', 'lastName'], '', notEquals),
              dynamic.makeFilter('isActive', true, equals),
            ).join('&&'),
            orderBy: dynamic.orderBy('firstName,lastName', 'asc'),
          });
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getUserPharmacistProfilesInfo: build.query<
      { sumOfPaymentsAmount: number; dateOfBirth: string },
      string
    >({
      query: (userPharmacistProfileID) => ({
        url: API_USER_PHARMACIST_PROFILES.GET_ALL_DYNAMIC,
        params: {
          select: dynamic.select('dateOfBirth'),
          filter: mergeFilters(
            dynamic.makeFilter('appIdentityUserID', userPharmacistProfileID, equals),
          ).join('&&'),

          take: 1,
        },
      }),
      transformResponse: transformResponseDynamicItemMaybe,
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getUserPharmacistProfile: build.query<UserPharmacistProfile, string>({
      queryFn: async (appIdentityUserID) => {
        try {
          const { data } = await ServiceUserPharmacistProfiles.getDynamic(appIdentityUserID);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_TAG, id }],
    }),
    postUserPharmacistProfile: build.mutation<void, UserPharmacistProfile>({
      queryFn: async (customer) => {
        try {
          await ServiceUserPharmacistProfiles.post(customer);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    patchUserPharmacistProfile: build.mutation<
      void,
      PatchPartial<UserPharmacistProfile, 'appIdentityUserID'>
    >({
      queryFn: async (data) => {
        try {
          await ServiceUserPharmacistProfiles.patch(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { appIdentityUserID }) => [
        { type: REVALIDATE_TAG, appIdentityUserID },
      ],
    }),
    deleteUserPharmacistProfile: build.mutation<void, Partial<UserPharmacistProfile>>({
      queryFn: async (data) => {
        try {
          await ServiceUserPharmacistProfiles.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: REVALIDATE_TAG, id }],
    }),
  }),
});
