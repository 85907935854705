import { useTranslate } from 'hooks/use-translate';
import { useCallback } from 'react';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form';
import { fieldToLabelKey } from 'utils/other';

export type FieldRenderProps = {
  field: ControllerRenderProps<any, any>;
  fieldState: ControllerFieldState;
};
export const useFieldProps = <Shared>(shared?: Shared) => {
  const { t } = useTranslate();

  return useCallback(
    (renderProps: FieldRenderProps) => {
      const { field, fieldState } = renderProps;
      const error = fieldState.error;
      return {
        ...field,
        ...shared,
        label: t(fieldToLabelKey(field.name)),
        error: Boolean(error),
        helperText: error && error.message ? t(error.message) : ' ',
      };
    },
    [t, shared],
  );
};
