import {
  all,
  call,
  cancel,
  delay,
  fork,
  put,
  SagaGenerator,
  select,
  takeEvery,
} from 'typed-redux-saga';
import { convertToDate } from 'utils/dates';
import { selectUserPharmacistProfilesBlockInvoices } from './selectors';
import { actionUserPharmacistProfilesBlock, actionUserPharmacistProfilesUnBlock } from './slice';

function* sagaBlockTimer(): SagaGenerator<any> {
  const initInvoices = yield* select(selectUserPharmacistProfilesBlockInvoices);

  yield* all(
    initInvoices.map((item) => {
      if (new Date().getTime() > convertToDate(item.endDate).getTime()) {
        return put(actionUserPharmacistProfilesUnBlock(item));
      }
      return null;
    }),
  );

  const newInvoices = yield* select(selectUserPharmacistProfilesBlockInvoices);

  if (newInvoices.length > 0) {
    yield* delay(1000);
    yield* call(sagaBlockTimer);
  }

  yield* cancel();
}

function* sagaBlocking() {
  let task = yield* fork(sagaBlockTimer);

  yield* takeEvery(
    [actionUserPharmacistProfilesBlock, actionUserPharmacistProfilesUnBlock],
    function* watcher() {
      if (task && task.isRunning()) return;
      task = yield* fork(sagaBlockTimer);
    },
  );
}
export const sagasUserPharmacistProfiles = [sagaBlocking()];
