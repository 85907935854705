import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  apiUserEmployeeProfilePermissions,
  IUserEmployeeProfilePermissions,
  ServiceUserEmployeeProfilePermissions,
} from 'services/user-employee-profile-permissions';
import { AppAsyncThunkConfig } from 'store';
import { dynamicNamespace } from 'utils/dynamic';
import { parseErrorData } from 'utils/service';
import { selectEmployeePermissionsOrderBy } from './selectors';
import { IGridEmployeePermissions } from './slice';

const dynamic = dynamicNamespace<IGridEmployeePermissions>();

export const actionEmployeePermissionsGetAllDynamic = createAsyncThunk<
  { value: IGridEmployeePermissions[] },
  void,
  AppAsyncThunkConfig
>(`EMPLOYEE_PERMISSIONS/getAllDynamic`, async (payload, { getState }) => {
  try {
    const { field, order } = selectEmployeePermissionsOrderBy(getState());
    const params = {
      select: dynamic.select(
        'id',
        'isActive',
        'title',
        'isAllowToEditSetting',
        'isAllowToEditLabels',
        'isAllowToEditProfile',
        'isAllowToEditEmployeeUsers',
        'isAllowToEditPermissions',
        'isAllowToViewLog',
        'isAllowToViewSensitiveInformation',
        'isAllowToResetSensitiveInformation',
        'isRoleOperation',
        'isRoleConsultant',
        'isRoleAdmin',
      ),
      orderBy: dynamic.orderBy(field as any, order),
    };
    const { data: result } = await ServiceUserEmployeeProfilePermissions.getAllDynamic<
      IGridEmployeePermissions,
      typeof params
    >(params);
    return result;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
export const actionEmployeePermissionsChangeItem = createAsyncThunk<
  void,
  Partial<IUserEmployeeProfilePermissions>,
  AppAsyncThunkConfig
>(`EMPLOYEE_PERMISSIONS/changeItem`, async (data, { dispatch }) => {
  try {
    const id = data.id;
    if (!id) {
      throw new Error('id is required');
    }
    await dispatch(
      apiUserEmployeeProfilePermissions.endpoints.patchUserEmployeeProfilePermission.initiate({
        ...data,
        id,
      }),
    );
    await dispatch(actionEmployeePermissionsGetAllDynamic()).unwrap();
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
