import { FixServerObject } from 'utils/types';
import * as yup from 'yup';

const API = 'ConsultRequestTypes';

export const API_CONSULT_REQUEST_TYPES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<ConsultRequestType, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<ConsultRequestType, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface IConsultRequestType
  extends FixServerObject<
    Components.Schemas.ConsultRequestType,
    | 'id'
    | 'rowIndex'
    | 'disclaimerTitle'
    | 'disclaimerSubTitle'
    | 'disclaimerHtml'
    | 'title'
    | 'isActive'
    | 'rank'
  > {}

export const schemaConsultRequestType = yup.object({
  title: yup.string().required('rule-required'),
  disclaimerTitle: yup.string(),
  disclaimerSubTitle: yup.string(),
  disclaimerHtml: yup.string(),
});

export class ConsultRequestType implements yup.InferType<typeof schemaConsultRequestType> {
  id!: string;
  title = '';
  disclaimerTitle = '';
  disclaimerSubTitle = '';
  disclaimerHtml = '';
  isActive = true;
  rank = 0;
}

export interface IGridConsultRequestType
  extends Pick<
    IConsultRequestType,
    | 'id'
    | 'rowIndex'
    | 'disclaimerTitle'
    | 'disclaimerSubTitle'
    | 'disclaimerHtml'
    | 'title'
    | 'isActive'
    | 'rank'
  > {}
