import { FixServerObject } from 'utils/types';
import * as yup from 'yup';

const API = 'Regions';

export const API_REGIONS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<Region, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<Region, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface IRegion extends FixServerObject<Components.Schemas.Region, 'id'> {}

type SchemaFields = Pick<IRegion, 'title'>;

export const schemaRegions: yup.SchemaOf<SchemaFields> = yup.object({
  title: yup.string().required('rule-required'),
});

export class Region implements yup.InferType<typeof schemaRegions> {
  id!: string;
  title = '';
  isActive = true;
}

export interface IGridRegion extends Pick<IRegion, 'id' | 'title' | 'rowIndex' | 'isActive'> {
  numberOfPharmacists: number;
}
