import { Loading } from 'components/loading';
import { APP_ROUTES } from 'configs';
import { useAppSelector } from 'hooks';
import { memo, PropsWithChildren, useMemo } from 'react';
import { Navigate, NavigateProps, To } from 'react-router-dom';
import { selectAccountPermissions } from 'store/auth';

const DEFAULT_TO = APP_ROUTES.DASHBOARD().config;

type AllowedValues = Exclude<ReturnType<typeof selectAccountPermissions>, null>;

interface Props {
  allowedValues: Partial<AllowedValues>;
  redirect?: To;
  RedirectProps?: Partial<NavigateProps>;
}
export const PermissionsProtector = memo<PropsWithChildren<Props>>(
  ({ allowedValues, RedirectProps, redirect = DEFAULT_TO, children }) => {
    const permissions = useAppSelector(selectAccountPermissions);
    const isAllowed = useMemo(() => {
      if (!permissions) return false;
      const entries = Object.entries(allowedValues) as [keyof AllowedValues, any][];

      return entries.every(([key, value]) => permissions[key] === value);
    }, [permissions, allowedValues]);
    if (!permissions) {
      return <Loading />;
    }
    if (!isAllowed) {
      return <Navigate {...RedirectProps} to={redirect} />;
    }

    return <>{children}</>;
  },
);
