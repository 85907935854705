import { contains, dynamicNamespace, DynamicOrder, equals, mergeFilters } from 'utils/dynamic';
import {
  apiRtk,
  behaviourMoveRows,
  decoratorRank,
  DynamicResult,
  DynamicService,
  transformResponseDynamic,
} from 'utils/service';
import { PatchPartial } from 'utils/types';
import { API_INVOICE_TYPES, IGridInvoiceType, IInvoiceType, InvoiceType } from './models';

const REVALIDATE_KEY = 'InvoiceTypes' as const;

const dynamic = dynamicNamespace<IGridInvoiceType>();

export * from './models';

export interface IGridInvoiceTypesParams {
  search: string;
  isActiveOnly: boolean;
  order: DynamicOrder;
  take: number;
  skip: number;
}

class Service extends DynamicService<InvoiceType> {
  @decoratorRank('rank')
  async post(data: InvoiceType) {
    return super.post(data);
  }
  moveRows = async function (newRows: Partial<InvoiceType>[], oldRows: Partial<InvoiceType>[]) {
    return behaviourMoveRows({
      mainField: 'id',
      moveField: 'rank',
      newRows,
      oldRows,
      requestPatch: ServiceInvoiceTypes.patch,
    });
  };
}

type ApiModel = InvoiceType;

export const ServiceInvoiceTypes = new Service({
  getAll: API_INVOICE_TYPES.GET_ALL_DYNAMIC,
  post: API_INVOICE_TYPES.POST,
  patch: API_INVOICE_TYPES.PATCH,
  delete: API_INVOICE_TYPES.DELETE,
});

export const apiInvoiceTypes = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getGridInvoiceTypes: build.query<
      DynamicResult<IGridInvoiceType, { count: true }>,
      IGridInvoiceTypesParams
    >({
      queryFn: async ({ search, isActiveOnly, order, take, skip }) => {
        try {
          const params = {
            filter: mergeFilters(
              dynamic.makeFilter(['title'], search, contains),
              isActiveOnly && dynamic.makeFilter('isActive', true, equals),
            ).join('&&'),
            select: dynamic.select(
              'id',
              'rowIndex',
              'title',
              'rank',
              'externalAppKey',
              'paymentRequired',
              'isActive',
            ),
            orderBy: dynamic.orderBy(order.field, order.order),
            take,
            skip,
            count: true,
          };
          const { data } = await ServiceInvoiceTypes.getAllDynamic<IGridInvoiceType, typeof params>(
            params,
          );
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getInvoiceType: build.query<ApiModel, string>({
      queryFn: async (id) => {
        try {
          const params = {
            select: dynamic.select('id', 'title', 'externalAppKey', 'isActive', 'paymentRequired'),
          };
          const { data } = await ServiceInvoiceTypes.getDynamic(id, params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    getInvoiceTypesOption: build.query<
      Pick<IInvoiceType, 'id' | 'title' | 'paymentRequired' | 'step'>[],
      void
    >({
      query: () => ({
        url: API_INVOICE_TYPES.GET_ALL_DYNAMIC,
        params: {
          filter: mergeFilters(dynamic.makeFilter(['isActive'], true, equals)).join('&&'),
          select: dynamic.select('id', 'title', 'paymentRequired', 'step'),
          orderBy: 'rank',
        },
      }),
      transformResponse: transformResponseDynamic,
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    postInvoiceType: build.mutation<void, ApiModel>({
      queryFn: async (data) => {
        try {
          await ServiceInvoiceTypes.post(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }, { type: REVALIDATE_KEY }],
    }),
    patchInvoiceType: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceInvoiceTypes.patch(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
    deleteInvoiceType: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceInvoiceTypes.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
    moveInvoiceTypes: build.mutation<
      void,
      { newRows: Partial<ApiModel>[]; oldRows: Partial<ApiModel>[] }
    >({
      queryFn: async ({ newRows, oldRows }) => {
        try {
          await ServiceInvoiceTypes.moveRows(newRows, oldRows);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { newRows }) => [
        { type: REVALIDATE_KEY },
        ...newRows.map((item) => ({ type: REVALIDATE_KEY, id: item.id })),
      ],
    }),
  }),
});
