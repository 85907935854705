import { Box, Theme, useMediaQuery } from '@mui/material';
import { memo } from 'react';
import { Outlet } from 'react-router-dom';
import { Header, Panel } from './components';
import style from './index.module.scss';

export const PrivateLayout = memo(() => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  return (
    <Box className={style.root}>
      <Header className={style.header} />
      {!isMobile && (
        <Box className={style.panel}>
          <Panel />
        </Box>
      )}
      <Box className={style.content}>
        <Outlet />
      </Box>
    </Box>
  );
});
