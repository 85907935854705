import { SvgIcon, SvgIconProps } from '@mui/material';

interface Props extends SvgIconProps {}

export const SvgLogo = (props: Props) => {
  return (
    <SvgIcon {...props} width="195" height="192" viewBox="0 0 195 192" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.9666 185.41C62.9083 181.77 62.5634 175.525 66.1963 171.46L88.1227 146.929C91.7556 142.864 97.9906 142.52 102.049 146.16C106.107 149.8 106.452 156.045 102.819 160.109L80.8929 184.641C77.26 188.705 71.025 189.049 66.9666 185.41ZM77.7073 161.052L79.0625 159.535L91.3083 170.518L89.9531 172.034L77.7073 161.052ZM105.666 127.303C102.033 131.367 102.378 137.612 106.436 141.252C110.494 144.891 116.729 144.547 120.362 140.483C123.995 136.418 123.65 130.173 119.591 126.534C115.533 122.894 109.298 123.238 105.666 127.303Z"
        fill="#369D49"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1663 132.177C11.2934 126.723 15.8107 122.405 21.2558 122.532L54.1202 123.3C59.5654 123.428 63.8765 127.952 63.7494 133.407C63.6223 138.861 59.1051 143.179 53.6599 143.052L20.7955 142.283C15.3504 142.156 11.0392 137.631 11.1663 132.177ZM36.6338 124.538L38.6654 124.586L38.2818 141.045L36.2502 140.998L36.6338 124.538ZM80.4114 123.915C74.9662 123.788 70.4489 128.106 70.3218 133.56C70.1947 139.015 74.5059 143.539 79.9511 143.667C85.3963 143.794 89.9135 139.476 90.0406 134.021C90.1677 128.567 85.8565 124.043 80.4114 123.915Z"
        fill="#B0D89B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.607 102.244C64.3978 97.8356 58.2289 96.8671 53.8284 100.081C49.4279 103.295 48.4621 109.475 51.6713 113.883C54.8805 118.292 61.0494 119.261 65.4499 116.047C69.8505 112.832 70.8162 106.653 67.607 102.244ZM18.9664 52.1892C23.3667 48.9753 29.5354 49.9436 32.7445 54.3522L52.113 80.9599C55.322 85.3685 54.3563 91.5477 49.956 94.7617C45.5556 97.9756 39.3869 97.0072 36.1778 92.5987L16.8094 65.9909C13.6003 61.5824 14.566 55.4032 18.9664 52.1892ZM40.5028 67.803L41.7002 69.4479L28.4195 79.1479L27.2221 77.5029L40.5028 67.803Z"
        fill="#97CD7A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.8227 9.14057C91.0593 10.6424 94.0892 16.1116 92.5901 21.3564L83.5424 53.0119C82.0433 58.2568 76.583 61.2911 71.3463 59.7893C66.1097 58.2875 63.0797 52.8183 64.5788 47.5735L73.6265 15.918C75.1256 10.6731 80.586 7.63878 85.8227 9.14057ZM86.7651 35.7525L86.2058 37.7091L70.4039 33.1774L70.9632 31.2207L86.7651 35.7525ZM76.3035 78.3375C77.8026 73.0928 74.7727 67.6237 69.5362 66.1219C64.2997 64.6202 58.8395 67.6544 57.3404 72.8992C55.8414 78.1439 58.8712 83.613 64.1078 85.1147C69.3443 86.6165 74.8045 83.5822 76.3035 78.3375Z"
        fill="#7EC46A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M160.876 35.1472C162.895 40.2145 160.432 45.9621 155.374 47.9849L124.845 60.1937C119.787 62.2165 114.049 59.7484 112.029 54.6812C110.01 49.6139 112.473 43.8662 117.531 41.8434L148.06 29.6347C153.118 27.6119 158.856 30.0799 160.876 35.1472ZM140.443 52.1823L138.556 52.9369L132.462 37.6461L134.349 36.8914L140.443 52.1823ZM100.42 69.9608C105.478 67.9381 107.942 62.1905 105.922 57.1234C103.903 52.0562 98.165 49.5883 93.1069 51.611C88.0488 53.6338 85.5856 59.3813 87.6051 64.4485C89.6247 69.5156 95.3623 71.9836 100.42 69.9608Z"
        fill="#51B849"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M122.093 84.5525C126.761 87.3641 132.821 85.8534 135.627 81.1781C138.433 76.5029 136.924 70.4336 132.256 67.622C127.588 64.8104 121.528 66.3211 118.722 70.9963C115.916 75.6715 117.425 81.7408 122.093 84.5525ZM186.338 111.721C183.531 116.396 177.472 117.907 172.804 115.095L144.631 98.1262C139.963 95.3146 138.453 89.2456 141.26 84.5706C144.066 79.8955 150.125 78.3848 154.793 81.1964L182.967 98.1654C187.635 100.977 189.144 107.046 186.338 111.721ZM160.435 105.725L158.693 104.676L167.163 90.5664L168.905 91.6155L160.435 105.725Z"
        fill="#51B849"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M143.413 176.419C138.017 177.159 133.044 173.377 132.305 167.972L127.847 135.347C127.108 129.942 130.883 124.96 136.28 124.22C141.676 123.48 146.649 127.262 147.388 132.668L151.846 165.292C152.585 170.697 148.809 175.679 143.413 176.419ZM131.842 152.445L131.566 150.428L147.851 148.195L148.127 150.212L131.842 152.445ZM124.28 109.248C125.018 114.654 129.992 118.436 135.388 117.696C140.784 116.956 144.56 111.974 143.821 106.569C143.082 101.163 138.109 97.3813 132.713 98.1212C127.317 98.8612 123.541 103.843 124.28 109.248Z"
        fill="#44AA49"
      />
    </SvgIcon>
  );
};
