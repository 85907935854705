import { UserEmployeeProfilePermissions } from 'services/user-employee-profile-permissions';

const API = 'Accounts';

export const API_AUTH = {
  GENERATE_PASSWORD: `${API}/GeneratePassword`,
  LOGIN: `${API}/LoginWithCode`,
  LOGOUT: `${API}/Logout`,
  REFRESH_TOKEN: `${API}/RefreshToken`,
  GET_CURRENT_USER: `${API}/GetCurrentAppUser`,
  PATCH_CURRENT_USER: `${API}/PatchCurrentUser`,
};

export interface CurrentAppUser {
  appUserID: string;
  email: string;
  isActive: true;
  languageID: string;
  name: string;
  userEmployeeProfilePermission: UserEmployeeProfilePermissions;
  userEmployeeProfileDepartmentID: string | null;
  userPhoto: string | null;
  accountantEmail: string;
  idNumber: number;
}

export interface JWT {
  jwt: {
    token: string;
    refreshToken: string;
  };
  expires: string;
}
