import { Typography } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import style from './index.module.scss';

interface State {
  hasError: boolean;
  error: Error | null;
  errorInfo: Error | null;
}

export class ErrorBoundary extends React.Component<PropsWithChildren<{}>, State> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({ error: error, errorInfo: errorInfo });
    console.log(error.stack);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={style.root}>
          <Typography variant={'h1'} color={'error'}>
            Something went wrong ;(
          </Typography>
          {this.state.error && (
            <Typography variant={'h2'} color={'error'}>
              {this.state.error.message}
            </Typography>
          )}
        </div>
      );
    }
    return this.props.children;
  }
}
