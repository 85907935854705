import { FixServerObject } from 'utils/types';
import * as yup from 'yup';

const API = 'PaymentTypeSubjects';

export const API_PAYMENT_TYPE_SUBJECTS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<PaymentTypeSubjects, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<PaymentTypeSubjects, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface IPaymentTypeSubjects
  extends FixServerObject<Components.Schemas.PaymentTypeSubject, 'id'> {}

type SchemaFields = Pick<IPaymentTypeSubjects, 'title'>;

export const schemaPaymentTypeSubjects: yup.SchemaOf<SchemaFields> = yup.object({
  title: yup.string().required('rule-required'),
});

export class PaymentTypeSubjects implements yup.InferType<typeof schemaPaymentTypeSubjects> {
  id!: string;
  title = '';
  isActive = true;
  defaultAmount = 0;
}

export interface IGridPaymentTypeSubjects
  extends Pick<IPaymentTypeSubjects, 'id' | 'title' | 'rowIndex' | 'isActive'> {
  numberOfPharmacists: number;
}
