import {
  contains,
  decoratorIsNotNullable,
  dynamicNamespace,
  DynamicOrder,
  equals,
  mergeFilters,
} from 'utils/dynamic';
import { apiRtk, DynamicResult, DynamicService, transformResponseDynamic } from 'utils/service';
import { PatchPartial } from 'utils/types';
import {
  API_INCOME_ACCOUNTS,
  IDashboardIncomeAccount,
  IDashboardIncomeAccountsArgs,
  IGridIncomeAccounts,
  IncomeAccounts,
} from './models';

const REVALIDATE_KEY = 'IncomeAccounts' as const;

const dynamic = dynamicNamespace<IGridIncomeAccounts>();

export * from './models';

export interface IGridIncomeAccountsParams {
  search: string;
  isActiveOnly: boolean;
  order: DynamicOrder;
  take: number;
  skip: number;
}

class Service extends DynamicService<IncomeAccounts> {}

type ApiModel = IncomeAccounts;

export const ServiceIncomeAccounts = new Service({
  getAll: API_INCOME_ACCOUNTS.GET_ALL_DYNAMIC,
  post: API_INCOME_ACCOUNTS.POST,
  patch: API_INCOME_ACCOUNTS.PATCH,
  delete: API_INCOME_ACCOUNTS.DELETE,
});

type SourceModel = Pick<ApiModel, 'id' | 'title' | 'accountCode'>;
type SourceModelResponse = Pick<ApiModel, 'id' | 'title'>;
export const apiIncomeAccounts = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getSourceIncomeAccounts: build.query<SourceModelResponse[], void>({
      query: () => ({
        url: API_INCOME_ACCOUNTS.GET_ALL_DYNAMIC,
        params: {
          filter: mergeFilters(dynamic.makeFilter('isActive', true, equals)).join('&&'),
          select: dynamic.select('id', 'title', 'accountCode'),
          orderBy: 'accountCode',
        },
      }),
      providesTags: [{ type: REVALIDATE_KEY }],
      transformResponse: (data: { value: SourceModel[] }) =>
        data.value.map((item) => ({
          id: item.id,
          title: `${item.accountCode} - ${item.title}`,
        })),
    }),
    getGridIncomeAccounts: build.query<
      DynamicResult<IGridIncomeAccounts, { count: true }>,
      IGridIncomeAccountsParams
    >({
      queryFn: async ({ search, isActiveOnly, order, take, skip }) => {
        try {
          const params = {
            filter: mergeFilters(
              dynamic.makeFilter(['title', 'accountCode'], search, contains),
              isActiveOnly && dynamic.makeFilter('isActive', true, equals),
            ).join('&&'),
            select: dynamic.select('id', 'rowIndex', 'title', 'accountCode', 'isActive'),
            orderBy: dynamic.orderBy(order.field, order.order),
            take,
            skip,
            count: true,
          };
          const { data } = await ServiceIncomeAccounts.getAllDynamic<
            IGridIncomeAccounts,
            typeof params
          >(params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getDashboardIncomeAccounts: build.query<
      IDashboardIncomeAccount[],
      IDashboardIncomeAccountsArgs
    >({
      query: ({ search, orderBy }) => ({
        url: API_INCOME_ACCOUNTS.GET_ALL_DYNAMIC,
        params: {
          select: dynamic.select('id', 'title', 'accountCode'),
          filter: mergeFilters(
            dynamic.makeFilter(['title', 'accountCode'], search, decoratorIsNotNullable(contains)),
            dynamic.makeFilter('isActive', true, equals),
          ).join('&&'),
          orderBy: dynamic.orderBy(orderBy.field, orderBy.order),
        },
      }),
      transformResponse: transformResponseDynamic,
      providesTags: [{ type: REVALIDATE_KEY }],
    }),

    getIncomeAccount: build.query<ApiModel, string>({
      queryFn: async (id) => {
        try {
          const params = {
            select: dynamic.select('id', 'title', 'accountCode', 'isActive'),
          };
          const { data } = await ServiceIncomeAccounts.getDynamic(id, params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    postIncomeAccount: build.mutation<void, ApiModel>({
      queryFn: async (data) => {
        try {
          await ServiceIncomeAccounts.post(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }, { type: REVALIDATE_KEY }],
    }),
    patchIncomeAccount: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceIncomeAccounts.patch(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
    deleteIncomeAccount: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceIncomeAccounts.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
  }),
});
