import {
  contains,
  decoratorIsNotNullable,
  dynamicNamespace,
  DynamicOrder,
  equals,
  mergeFilters,
} from 'utils/dynamic';
import { apiRtk, DynamicResult, DynamicService, transformResponseDynamic } from 'utils/service';
import { PatchPartial } from 'utils/types';
import {
  API_PAYMENT_ACCOUNTS,
  IDashboardPaymentAccounts,
  IDashboardPaymentAccountsArgs,
  IGridPaymentAccounts,
  IPaymentAccounts,
  PaymentAccounts,
} from './models';

const REVALIDATE_KEY = 'PaymentAccounts' as const;

const dynamic = dynamicNamespace<IPaymentAccounts>();

export * from './models';

export interface IGridPaymentAccountsParams {
  search: string;
  isActiveOnly: boolean;
  order: DynamicOrder;
  take: number;
  skip: number;
}

class Service extends DynamicService<PaymentAccounts> {}

type ApiModel = PaymentAccounts;

export const ServicePaymentAccounts = new Service({
  getAll: API_PAYMENT_ACCOUNTS.GET_ALL_DYNAMIC,
  post: API_PAYMENT_ACCOUNTS.POST,
  patch: API_PAYMENT_ACCOUNTS.PATCH,
  delete: API_PAYMENT_ACCOUNTS.DELETE,
});

type SourceModel = Pick<ApiModel, 'id' | 'title' | 'accountCode'>;

export const apiPaymentAccounts = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getSourcePaymentAccounts: build.query<SourceModel[], void>({
      query: () => ({
        url: API_PAYMENT_ACCOUNTS.GET_ALL_DYNAMIC,
        params: {
          filter: mergeFilters(dynamic.makeFilter(['isActive'], true, equals)).join('&&'),
          select: dynamic.select('id', 'title', 'accountCode'),
          orderBy: dynamic.orderBy('title', 'asc'),
        },
      }),
      providesTags: [{ type: REVALIDATE_KEY }],
      transformResponse: transformResponseDynamic,
    }),
    getGridPaymentAccounts: build.query<
      DynamicResult<IGridPaymentAccounts, { count: true }>,
      IGridPaymentAccountsParams
    >({
      queryFn: async ({ search, isActiveOnly, order, take, skip }) => {
        try {
          const params = {
            filter: mergeFilters(
              dynamic.makeFilter('title', search, contains),
              isActiveOnly && dynamic.makeFilter('isActive', true, equals),
            ).join('&&'),
            select: dynamic.select('id', 'rowIndex', 'title', 'accountCode', 'isActive'),
            orderBy: dynamic.orderBy(order.field, order.order),
            take,
            skip,
            count: true,
          };
          const { data } = await ServicePaymentAccounts.getAllDynamic<
            IGridPaymentAccounts,
            typeof params
          >(params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getDashboardPaymentAccounts: build.query<
      IDashboardPaymentAccounts[],
      IDashboardPaymentAccountsArgs
    >({
      query: ({ search, orderBy }) => ({
        url: API_PAYMENT_ACCOUNTS.GET_ALL_DYNAMIC,
        params: {
          select: dynamic.select('id', 'title', 'accountCode'),
          filter: mergeFilters(
            dynamic.makeFilter(['title', 'accountCode'], search, decoratorIsNotNullable(contains)),
            dynamic.makeFilter('isActive', true, equals),
          ).join('&&'),
          orderBy: dynamic.orderBy(orderBy.field, orderBy.order),
        },
      }),
      transformResponse: transformResponseDynamic,
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getPaymentAccount: build.query<ApiModel, string>({
      queryFn: async (id) => {
        try {
          const params = {
            select: dynamic.select('id', 'title', 'accountCode', 'isActive'),
          };
          const { data } = await ServicePaymentAccounts.getDynamic(id, params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    postPaymentAccount: build.mutation<void, ApiModel>({
      queryFn: async (data) => {
        try {
          await ServicePaymentAccounts.post(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }, { type: REVALIDATE_KEY }],
    }),
    patchPaymentAccount: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServicePaymentAccounts.patch(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
    deletePaymentAccount: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServicePaymentAccounts.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
  }),
});
