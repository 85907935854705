import React from 'react';
import { composeFunctions } from './other';

export const getHandlerKayPress = (key: string, cb: () => void) => {
  return (e: React.KeyboardEvent) => {
    if (e.key === key) {
      cb();
    }
  };
};

export const handleEventPrevent = (e: Event) => {
  e.preventDefault();
};
export const handleEventStop = <T extends React.MouseEvent>(e: T) => {
  e.stopPropagation();
};

export const handleEventStopAndPrevent = composeFunctions(handleEventStop, handleEventPrevent);
