import { SvgIcon, SvgIconProps } from '@mui/material';

interface Props extends SvgIconProps {}

export const SvgLogoText = (props: Props) => {
  return (
    <SvgIcon {...props} width="78" height="24" viewBox="0 0 78 24">
      <path
        d="M4.39619 0C5.40622 0 6.16589 0.244033 6.67522 0.732098C7.18887 1.22016 7.44569 1.95226 7.44569 2.92839V7.70322H5.81411L5.80116 2.80529C5.80116 2.28268 5.68894 1.90043 5.46449 1.65856C5.24004 1.41237 4.8861 1.28927 4.40267 1.28927H3.36675C3.26315 1.52682 3.18114 1.80757 3.12071 2.13151C3.0646 2.45112 3.03655 2.76858 3.03655 3.08388V5.47453C3.03655 6.22175 2.84663 6.7962 2.46679 7.19788C2.09127 7.59524 1.5582 7.79392 0.867585 7.79392C0.712196 7.79392 0.565441 7.78744 0.427318 7.77449C0.293511 7.76153 0.151072 7.74425 0 7.72266V6.43339C0.0733778 6.44203 0.146756 6.44851 0.220133 6.45282C0.293511 6.45282 0.364731 6.45282 0.433792 6.45282C0.774783 6.45282 1.02729 6.36428 1.19131 6.1872C1.35533 6.01011 1.43734 5.73368 1.43734 5.35792V3.22641C1.43734 2.90679 1.47835 2.56558 1.56036 2.20277C1.64668 1.83564 1.77402 1.53114 1.94235 1.28927H0.628028V0H4.39619Z"
        fill="currentColor"
      />
      <path d="M9.5727 7.70322V1.28927H8.79575V0H11.2172V7.70322H9.5727Z" fill="currentColor" />
      <path
        d="M13.3183 7.70322L13.3248 0H16.4585C17.4728 0 18.2346 0.244033 18.744 0.732098C19.2533 1.22016 19.5101 1.95226 19.5144 2.92839V7.70322H17.8764V2.80529C17.8764 2.28268 17.762 1.90043 17.5332 1.65856C17.3088 1.41237 16.9548 1.28927 16.4714 1.28927H14.9629L14.9564 7.70322H13.3183Z"
        fill="currentColor"
      />
      <path
        d="M24.1212 7.70322V6.43987C24.7039 6.41827 25.1657 6.23687 25.5067 5.89565C25.8477 5.55012 26.0182 5.06205 26.0182 4.43146V1.28927H21.4148V0H27.6627V4.39906C27.6627 5.08149 27.5181 5.66674 27.2289 6.1548C26.9398 6.63855 26.5297 7.01215 25.9988 7.27562C25.4722 7.53909 24.8463 7.68163 24.1212 7.70322ZM21.5184 10.3984V4.91736C21.5184 4.4293 21.5659 4.06649 21.6609 3.82893C21.7601 3.58706 21.8465 3.4035 21.9198 3.27824H23.2471C23.1996 3.45965 23.163 3.66913 23.1371 3.90668C23.1155 4.14423 23.1047 4.43146 23.1047 4.76835V10.3984H21.5184Z"
        fill="currentColor"
      />
      <path d="M29.984 7.70322V1.28927H29.207V0H31.6285V7.70322H29.984Z" fill="currentColor" />
      <path
        d="M36.6043 7.70322V2.48136C36.6043 2.10559 36.4964 1.81405 36.2806 1.60673C36.0648 1.39509 35.7605 1.28927 35.3677 1.28927H32.9203V0H35.4648C36.3324 0 37.0122 0.226756 37.5043 0.680268C38.0006 1.13378 38.2488 1.76654 38.2488 2.57854V7.70322H36.6043Z"
        fill="currentColor"
      />
      <path
        d="M44.5195 7.70322V2.79234C44.5195 2.27404 44.3943 1.89395 44.144 1.65208C43.898 1.4102 43.5095 1.28927 42.9786 1.28927H39.9809V0H43.0822C44.0793 0 44.8411 0.244033 45.3677 0.732098C45.8986 1.22016 46.164 1.9285 46.164 2.85712V7.70322H44.5195ZM39.9744 7.70322V5.06637C39.9744 4.57399 40.0219 4.20902 40.1169 3.97147C40.2161 3.72959 40.3046 3.54603 40.3823 3.42077H41.6966C41.6578 3.60218 41.6254 3.81382 41.5995 4.05569C41.5736 4.29324 41.5607 4.58047 41.5607 4.91736V7.70322H39.9744Z"
        fill="currentColor"
      />
      <path d="M51.4346 10.3984V1.28927H50.6382V0H53.0532V10.3984H51.4346Z" fill="currentColor" />
      <path d="M55.3744 7.70322V1.28927H54.5975V0H57.019V7.70322H55.3744Z" fill="currentColor" />
      <path
        d="M58.5179 7.77449C58.4532 7.77449 58.3841 7.77233 58.3108 7.76801C58.2417 7.76369 58.1748 7.75505 58.11 7.74209V6.25198C58.1964 6.26494 58.2827 6.27574 58.369 6.28438C58.4553 6.2887 58.5352 6.29086 58.6086 6.29086C58.9841 6.28222 59.31 6.21095 59.5862 6.07706C59.8668 5.94316 60.0977 5.75744 60.279 5.51989C60.4646 5.27801 60.6027 5.00159 60.6934 4.69061V1.28927H58.9841V0H62.312V3.93259C62.312 4.34723 62.353 4.75107 62.435 5.14412L62.9595 7.70322H61.4379L60.9847 5.65594H60.9394C60.879 6.04034 60.7365 6.39451 60.5121 6.71845C60.2876 7.03807 60.0028 7.29506 59.6574 7.48942C59.3165 7.67946 58.9366 7.77449 58.5179 7.77449Z"
        fill="currentColor"
      />
      <path
        d="M67.3914 7.70322V2.48136C67.3914 2.10559 67.2835 1.81405 67.0676 1.60673C66.8518 1.39509 66.5475 1.28927 66.1547 1.28927H63.7074V0H66.2519C67.1195 0 67.7993 0.226756 68.2913 0.680268C68.7877 1.13378 69.0359 1.76654 69.0359 2.57854V7.70322H67.3914Z"
        fill="currentColor"
      />
      <path
        d="M76.1094 7.70322L70.6579 0H72.4967L77.9417 7.70322H76.1094ZM70.9557 7.70322V5.04046C70.9557 4.28461 71.0895 3.68424 71.3571 3.23937C71.6248 2.7945 72.0564 2.46624 72.6521 2.2546L73.2542 3.08388C72.7794 3.33439 72.542 3.76415 72.542 4.37315V7.70322H70.9557ZM78 0L75.4037 4.76187L74.3354 3.47908L76.1548 0H78Z"
        fill="currentColor"
      />
      <path
        d="M34.048 17.3722V13.8349H35.6925V16.083H39.5513V17.3722H34.048ZM40.1405 17.1261L37.46 23.7862H35.7119L38.8909 16.083H40.1405V17.1261Z"
        fill="currentColor"
      />
      <path
        d="M46.7608 23.7862L41.3093 16.083H43.148L48.5931 23.7862H46.7608ZM41.6071 23.7862V21.1234C41.6071 20.3676 41.7409 19.7672 42.0085 19.3223C42.2761 18.8775 42.7078 18.5492 43.3034 18.3376L43.9056 19.1669C43.4308 19.4174 43.1934 19.8471 43.1934 20.4561V23.7862H41.6071ZM48.6514 16.083L46.0551 20.8449L44.9868 19.5621L46.8061 16.083H48.6514Z"
        fill="currentColor"
      />
      <path
        d="M53.0574 23.7862V18.5643C53.0574 18.1886 52.9495 17.897 52.7337 17.6897C52.5179 17.4781 52.2136 17.3722 51.8208 17.3722H49.3734V16.083H51.9179C52.7855 16.083 53.4653 16.3097 53.9574 16.7632C54.4537 17.2168 54.7019 17.8495 54.7019 18.6615V23.7862H53.0574Z"
        fill="currentColor"
      />
      <path
        d="M58.1886 16.083V22.16C58.5727 22.3242 58.9849 22.4559 59.4252 22.5552C59.8698 22.6503 60.2885 22.6978 60.6813 22.6978C61.2812 22.6978 61.8165 22.6135 62.2869 22.4451C62.7617 22.2723 63.1351 22.0067 63.407 21.6482C63.6833 21.2897 63.8214 20.8319 63.8214 20.2747V16.083H65.4141V20.4496C65.4141 21.0457 65.2911 21.5662 65.0451 22.011C64.7991 22.4559 64.4602 22.8273 64.0286 23.1254C63.6013 23.4191 63.1071 23.6393 62.5459 23.7862C61.9848 23.9287 61.387 24 60.7525 24C60.0835 24 59.3885 23.9201 58.6677 23.7603C57.9469 23.5962 57.2584 23.3586 56.6023 23.0476V16.083H58.1886ZM59.8137 21.4862C59.9345 21.3178 60.0252 21.0824 60.0856 20.7801C60.1504 20.4777 60.1827 20.143 60.1827 19.7759V16.083H61.7755V19.4454C61.7755 19.8817 61.7237 20.279 61.6201 20.6375C61.5165 20.996 61.3633 21.2854 61.1604 21.5057L59.8137 21.4862Z"
        fill="currentColor"
      />
      <path
        d="M68.0462 20.4302V17.3722H66.7448V16.083H69.6907V20.4302H68.0462Z"
        fill="currentColor"
      />
      <path
        d="M70.9307 23.7862V22.4905H74.8672L76.5052 22.4321L77.4181 22.5488V23.7862H70.9307ZM74.8672 23.4104V18.8753C74.8672 18.357 74.7442 17.9769 74.4981 17.7351C74.2521 17.4932 73.8615 17.3722 73.3263 17.3722H71.3515V16.083H73.4363C74.4248 16.083 75.1823 16.327 75.7089 16.8151C76.2398 17.3031 76.5052 18.0115 76.5052 18.9401V23.4104H74.8672Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
