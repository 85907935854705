import { useCallback, useMemo, useState } from 'react';
import { useMountedRef } from './use-mounted-ref';

type Request<M = any> = (...args: any[]) => Promise<{ data: M }>;
type RequestModel<T> = T extends Request<infer U> ? U : unknown;
type Options<T> = { parser: (prev: T | undefined, next: T) => T };
enum STATUS {
  IS_LOADING,
  DEFAULT,
}
const defaultParser = <T>(prev: T | undefined, next: T) => {
  return next;
};
export const useFetchDynamicAutocomplete = <R extends Request, M extends RequestModel<R>>(
  request: R,
  options?: Options<M>,
) => {
  const [status, setStatus] = useState(STATUS.DEFAULT);
  const [error, setError] = useState<Error | null>(null);
  const [lastTimestamp, setLastTimestamp] = useState<number | null>(null);
  const { parser = defaultParser } = options || {};

  const [data, setData] = useState<M>();

  const mountedRef = useMountedRef();
  const trigger = useCallback(
    async (...args: Parameters<R>) => {
      setError(null);
      setStatus(STATUS.IS_LOADING);
      try {
        const result = await request(...args);

        setData((prev) => parser(prev, result.data));
        return { ...result, error: undefined };
      } catch (e: any) {
        if (mountedRef.current) {
          setError(e);
        }
        return { error: e as Error, data: undefined };
      } finally {
        if (mountedRef.current) {
          setStatus(STATUS.DEFAULT);
          setLastTimestamp(new Date().getTime());
        }
      }
    },
    // eslint-disable-next-line
    [mountedRef, request, parser],
  );

  const value = useMemo(() => {
    return {
      data,
      setData,
      error,
      status,
      isLoading: status === STATUS.IS_LOADING,
      lastTimestamp,
    };
  }, [data, error, status, lastTimestamp]);
  return [trigger, value] as const;
};
