const API = 'LabelSources';

export const API_LABEL_SOURCES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<LabelSource, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<LabelSource, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface ILabelSource extends Components.Schemas.LabelSource {}

export enum LABEL_SOURCE_KEYS {
  CRM = 'crm',
}

export enum LABEL_SOURCE_ID {
  CRM = '59cfc06b-e14a-4a12-8e47-52d061eb125a',
}
export interface LabelSource {
  id: string;
  title: string;
  key: LABEL_SOURCE_KEYS;
}
