import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import React, { memo, useEffect } from 'react';
import { AppThemeOptions } from 'styles/theme';

import { useDetectLanguage } from 'hooks';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';

export const themeLtr = createTheme(AppThemeOptions);
export const cacheLtr = createCache({
  key: 'muiltr',
  stylisPlugins: [prefixer],
  prepend: true,
});

export const themeRtl = createTheme({ ...AppThemeOptions, direction: 'rtl' });
export const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
  prepend: true,
});

interface Props {
  children: React.ReactNode;
}
export const AppThemeProvider = memo<Props>(({ children }) => {
  const { isRtl } = useDetectLanguage();

  useEffect(() => {
    document.getElementsByTagName('html')[0].setAttribute('dir', isRtl ? 'rtl' : 'ltr');
  }, [isRtl]);

  const theme = isRtl ? themeRtl : themeLtr;
  const cache = isRtl ? cacheRtl : cacheLtr;

  return (
    <CacheProvider value={cache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </CacheProvider>
  );
});
