const API = 'UserEmployeeProfilePermissions';

export const API_USER_EMPLOYEE_PROFILE_PERMISSIONS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<UserEmployeeProfilePermissions, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<UserEmployeeProfilePermissions, 'id'>) => `${API}/Delete/${data.id}`,
};

export class UserEmployeeProfilePermissions {
  id!: string;
  isActive = true;
  title = '';
  isAllowToEditSetting = false;
  isAllowToEditLabels = false;
  isAllowToEditProfile = false;
  isAllowToEditEmployeeUsers = false;
  isAllowToEditPermissions = false;
  isAllowToViewLog = false;
  isAllowToViewSensitiveInformation = false;
  isAllowToResetSensitiveInformation = false;
  isRoleOperation = false;
  isRoleConsultant = false;
  isRoleAdmin = false;
}

export interface IUserEmployeeProfilePermissions extends UserEmployeeProfilePermissions {}
