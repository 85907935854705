import { LoadingButtonProps } from '@mui/lab';
import { Button } from '@mui/material';
import { AppProgressCircular } from 'components/app-progress-circular';
import React from 'react';
import style from './index.module.scss';

interface Props extends LoadingButtonProps {}

export const LoadingButton: React.FC<Props> = ({ children, loading, ...rest }) => (
  <Button {...rest}>
    {loading ? (
      <AppProgressCircular size={'1.6rem'} classes={{ circularProgress: style.circularProgress }} />
    ) : (
      children
    )}
  </Button>
);
