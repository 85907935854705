import { actionAccountChangeData, actionAccountGetUser } from 'store/auth/actions';
import { actionUserEmployeesRevalidateData } from 'store/user-employees';
import { debounce, put } from 'typed-redux-saga';

function* watcherAccountChange() {
  yield* put(actionAccountGetUser({ isOnTheBackground: true }));
  yield* put(actionUserEmployeesRevalidateData());
}
export const sagasAccounts = [
  debounce(5000, actionAccountChangeData.fulfilled, watcherAccountChange),
];
