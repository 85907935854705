import { FORMAT_DATE_SEPARATOR_SLASH } from 'configs/const';
import { format } from 'date-fns';
import { convertToDate, DateValue } from './dates';
import { PromiseType } from './types';

export const setToModel = <Model extends { [x: string]: any }>(
  model: Model,
  ...args: Partial<Model>[]
) => {
  const argsRevers = args.filter(Boolean).reverse();
  const keys = Object.keys(model);
  return keys.reduce<Partial<Model>>((acc, key: keyof Model) => {
    const targetValue: Partial<Model> | undefined = argsRevers.find(
      (item) => item.hasOwnProperty(key) && item[key] !== '' && item[key] !== null,
    );
    acc[key] = targetValue ? targetValue[key] : model[key];
    return acc;
  }, {}) as Model;
};
export const keepToModel = <Model extends { [x: string]: any }>(
  model: Model,
  arg: Partial<Model>,
) => {
  const argsRevers = [arg].filter(Boolean).reverse();
  const keys = Object.keys(model);
  return keys.reduce<Partial<Model>>((acc, key: keyof Model) => {
    const targetValue: Partial<Model> | undefined = argsRevers.find((item) =>
      item.hasOwnProperty(key),
    );
    if (targetValue) {
      acc[key] = targetValue[key];
    }
    return acc;
  }, {} as typeof arg);
};
const dec2hex = (dec: number) => {
  return dec.toString(16).padStart(2, '0');
};
export type ArgumentTypes<F extends Function> = F extends (...args: infer A) => any ? A : never;
export const getRandomString = (length = 10) => {
  const arr = new Uint8Array((length || 40) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join('');
};

export const createMap = <M extends Record<string, any>>(source: M[], key: keyof M) => {
  return source.reduce((acc, item) => {
    acc[item[key]] = item;
    return acc;
  }, {} as Record<string, M | undefined>);
};

export const throttle = <T extends Function>(callback: T, limit: number) => {
  let waiting = false;
  let latestArguments: Array<any> | null = null;
  let latestContext: any | null = null;

  function wrapper(...args: any[]) {
    if (waiting) {
      latestArguments = args;
      // @ts-ignore
      latestContext = this;
      return;
    }

    // @ts-ignore
    callback.apply(this, args);

    waiting = true;

    setTimeout(function () {
      waiting = false;

      if (latestArguments) {
        callback.apply(latestContext, latestArguments);

        latestArguments = null;
        latestContext = null;
      }
    }, limit);
  }

  return wrapper;
};

export const debounce = <T extends Function>(cb: T, delay: number) => {
  let timerID: NodeJS.Timeout | null = null;
  return (...args: ArgumentTypes<T>) => {
    if (timerID) {
      clearTimeout(timerID);
    }
    timerID = setTimeout(() => {
      cb(...args);
      timerID = null;
    }, delay);
  };
};
export const getUrlExtension = (str: string) => {
  return str.split('.').pop()?.trim();
};
export const bytesToMB = (value: number | string) => {
  return (Number(value) / 1024 / 1024).toFixed(2);
};
const replaceTranslate = (value: string, replacer: (v: string) => string) => {
  return String(value).replace(/{{[a-zA-Z]+?}}/g, (v: string) => {
    const key = v.substring(2, v.length - 2);
    return replacer(key);
  });
};
export const calcTranslate = (value: string, payload: { [x: string]: any } = {}) => {
  return replaceTranslate(value, (key) => {
    return payload[key] === undefined ? '-- --' : payload[key];
  });
};

export const fieldToLabelKey = <T extends Record<string, any> = any>(field: keyof T) => {
  return String(field)
    .replace(/ID/g, '')
    .replace(/[A-Z]+/g, (substring) => {
      return `-${substring}`;
    })
    .toLowerCase()
    .replace(/^-/gi, '');
};

type AnyFunction = (...args: any[]) => any;
export const composeFunctions = <T extends (...args: any[]) => any>(
  cb: T,
  ...functions: (AnyFunction | undefined)[]
) => {
  return (...args: any[]) => {
    const result = cb(...args);
    (functions.filter(Boolean) as AnyFunction[]).forEach((fn) => {
      fn(...args);
    });
    return result;
  };
};

export const createGithubLink = (userName?: string) => {
  if (!userName) return undefined;
  return `https://github.com/${userName}`;
};

export const createSlackLink = (memberID?: string) => {
  if (!memberID) return undefined;
  return `https://auriontechnology.slack.com/team/${memberID}`;
};

type AsyncFunction = (...args: any[]) => Promise<any>;

export const debounceAsync = <T extends AsyncFunction>(fn: T, wait: number) => {
  let timerID: NodeJS.Timeout | null = null;

  return function (...args: ArgumentTypes<T>) {
    if (timerID) {
      clearTimeout(timerID);
    }

    return new Promise<PromiseType<ReturnType<T>>>((resolve, reject) => {
      timerID = setTimeout(() => {
        fn(...args)
          .then(resolve)
          .catch(reject);
      }, wait);
    });
  };
};

export const replaceStringToHtml = (value: string) => {
  return value.replace(/(?:\r\n|\r|\n)/g, '<br>');
};

export const makeDateKey = <T extends { date: DateValue }>(
  dataItem: T,
  dateFormat = FORMAT_DATE_SEPARATOR_SLASH,
) => {
  return format(convertToDate(dataItem.date), dateFormat);
};
