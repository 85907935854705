import { format } from 'date-fns';
import {
  AvailableInvoiceNumbersSource,
  IUserPharmacistProfileInvoiceDetails,
  ServiceUserPharmacistProfilePayments,
  UserPharmacistProfileInvoiceDetailsExportToExcel,
} from 'services/user-pharmacist-profile-invoice-details';
import {
  contains,
  dateRange,
  decoratorIsNotNullable,
  dynamicNamespace,
  DynamicOrder,
  equals,
  mergeFilters,
  more,
  notEquals,
} from 'utils/dynamic';
import { ValueFileUploaderFile } from 'utils/file-uploader';
import {
  apiApp,
  apiRtk,
  DynamicResult,
  DynamicService,
  transformResponseDynamic,
} from 'utils/service';
import { PatchPartial } from 'utils/types';
import { MediaUpload, ServiceMediaUploads } from '../media-uploads';
import {
  API_USER_PHARMACIST_PROFILE_INVOICES,
  ConvertUserPharmacistProfileInvoicesGet,
  ConvertUserPharmacistProfileInvoicesInput,
  CreateReceiptUserPharmacistProfileInvoicesInput,
  IDashboardPaymentInvoices,
  IDashboardPaymentInvoicesArgs,
  IgnoreAndDuplicateUserPharmacistProfileInvoicesInput,
  IGridOpenInvoices,
  IGridOpenInvoicesArgs,
  IGridUserPharmacistProfileInvoices,
  IUserPharmacistProfileInvoices,
  UserPharmacistProfileInvoices,
  UserPharmacistProfileInvoiceSendEmail,
  UserPharmacistProfileInvoicesExtended,
} from './models';

const dynamic = dynamicNamespace<IUserPharmacistProfileInvoices>();

const REVALIDATE_TAG = 'UserPharmacistProfileInvoiceDetails' as const;

export const makeInvoiceNumber = () => `P${format(new Date(), 'yyyyMMdd.HHmm')}`;

export * from './models';

class Service extends DynamicService<UserPharmacistProfileInvoices> {
  convertInvoice = async (input: ConvertUserPharmacistProfileInvoicesInput) => {
    const { invoiceID, invoiceTypeID, tp } = input;
    const { data } = await this.getDynamic<ConvertUserPharmacistProfileInvoicesGet>(invoiceID, {
      select: dynamic.select(
        'id',
        'isActive',
        'userPharmacistProfileID',
        'invoiceTypeID',
        'invoiceType.title as invoiceTypeTitle',
        'invoiceNumber',
        'invoiceDate',
        'invoiceURL',
        'paymentDate',
        'isSent',
        'isPaid',
        'userPharmacistProfileInvoiceDetails as userPharmacistProfileInvoiceDetails',
        'internalRemarks',
        'cardRemarks',
      ),
    });
    const {
      userPharmacistProfileInvoiceDetails,
      cardRemarks,
      internalRemarks,
      invoiceNumber,
      invoiceTypeTitle,
      id,
      ...rest
    } = data;
    const onUpdateRemarkFields = (field: string) =>
      field
        ? `${field}; ${tp('based-on', {
            currentInvoiceType: invoiceTypeTitle,
            currentInvoiceNumber: invoiceNumber,
          })}`
        : tp('based-on', {
            currentInvoiceType: invoiceTypeTitle,
            currentInvoiceNumber: invoiceNumber,
          });

    await this.patch({
      id,
      internalRemarks: tp('converted'),
      ignore: true,
    });

    const result = await this.post({
      ...rest,
      cardRemarks: onUpdateRemarkFields(cardRemarks),
      invoiceTypeID,
      internalRemarks: null,
      ignore: false,
      invoiceURL: null,
      invoiceDate: new Date().toISOString(),
      invoiceNumber: makeInvoiceNumber(),
    } as any);

    const createdEntity = result.data;

    const userPharmacistProfileInvoiceID = createdEntity.id;

    await Promise.all(
      data.userPharmacistProfileInvoiceDetails.map((details) => {
        const dataToSave = {
          ...details,
          userPharmacistProfileInvoiceID,
          id: undefined,
          rowIndex: undefined,
        };
        return ServiceUserPharmacistProfilePayments.post(dataToSave as any);
      }),
    );

    return result;
  };
  createReceipt = async (props: CreateReceiptUserPharmacistProfileInvoicesInput) => {
    const { invoices, invoiceTypeID, userPharmacistProfileID, cardRemarks, listIDs } = props;
    const result = await this.post({
      invoiceTypeID,
      invoiceDate: new Date().toISOString(),
      invoiceNumber: makeInvoiceNumber(),
      userPharmacistProfileID,
      cardRemarks,
    } as any);

    await Promise.all(
      listIDs.map((id) => {
        return this.patch({
          id,
          ignore: true,
        });
      }),
    );

    const createdEntity = result.data;

    const userPharmacistProfileInvoiceID = createdEntity.id;

    await Promise.all(
      invoices.map((invoice) =>
        invoice.userPharmacistProfileInvoiceDetails.map(
          (details: IUserPharmacistProfileInvoiceDetails) => {
            const dataToSave = {
              ...details,
              userPharmacistProfileInvoiceID,
              id: undefined,
              rowIndex: undefined,
            };
            return ServiceUserPharmacistProfilePayments.post(dataToSave as any);
          },
        ),
      ),
    );

    return result;
  };
  ignoreAndDuplicateInvoice = async (
    input: IgnoreAndDuplicateUserPharmacistProfileInvoicesInput,
  ) => {
    const { invoiceID } = input;

    const { data } = await this.getDynamic<ConvertUserPharmacistProfileInvoicesGet>(invoiceID, {
      select: dynamic.select(
        'isActive',
        'userPharmacistProfileID',
        'invoiceTypeID',
        'paymentDate',
        'isPaid',
        'userPharmacistProfileInvoiceDetails as userPharmacistProfileInvoiceDetails',
      ),
    });

    const { userPharmacistProfileInvoiceDetails, ...rest } = data;

    const result = await this.post({
      ...rest,
      ignore: false,
      isSent: false,
      invoiceDate: new Date().toISOString(),
      invoiceNumber: makeInvoiceNumber(),
    } as any);

    const createdEntity = result.data;

    const userPharmacistProfileInvoiceID = createdEntity.id;

    await Promise.all(
      data.userPharmacistProfileInvoiceDetails.map((details) => {
        const dataToSave = {
          ...details,
          userPharmacistProfileInvoiceID,
          id: undefined,
          rowIndex: undefined,
        };
        return ServiceUserPharmacistProfilePayments.post(dataToSave as any);
      }),
    );

    return result;
  };
}

export const ServiceUserPharmacistProfileInvoices = new Service({
  getAll: API_USER_PHARMACIST_PROFILE_INVOICES.GET_ALL_DYNAMIC,
  post: API_USER_PHARMACIST_PROFILE_INVOICES.POST,
  patch: API_USER_PHARMACIST_PROFILE_INVOICES.PATCH,
  delete: API_USER_PHARMACIST_PROFILE_INVOICES.DELETE,
});

export interface IGridUserPharmacistProfileInvoicesParams {
  search: string;
  take?: number;
  skip?: number;
  orderBy: DynamicOrder;
  userPharmacistProfileID: string;
  isActiveOnly: boolean;
}

interface UserPharmacistProfileInvoicesResponse extends UserPharmacistProfileInvoicesExtended {
  userPharmacistProfileInvoiceDetailsItemsCount: number;
}

export const apiUserPharmacistProfileInvoices = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getAvailableOpenInvoicesInvoiceNumbersSource: build.query<
      AvailableInvoiceNumbersSource[],
      { isActiveOnly: boolean }
    >({
      // @ts-ignore
      queryFn: async ({ isActiveOnly }) => {
        try {
          const {
            data: { value },
          } = await ServiceUserPharmacistProfileInvoices.getAllDynamic({
            filter: mergeFilters(
              dynamic.makeFilter('isPaid', false, decoratorIsNotNullable(equals)),
              isActiveOnly && dynamic.makeFilter('ignore', false, decoratorIsNotNullable(equals)),
              dynamic.makeFilter('invoiceType.step', 2, decoratorIsNotNullable(equals)),
              dynamic.makeFilter('userPharmacistProfileInvoiceDetails.count()', 0, more),
            ).join('&&'),
            select: dynamic.select('invoiceNumber as title', 'id'),
            count: true,
            orderBy: dynamic.orderBy('invoiceNumber', 'asc'),
          });
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    getGridOpenInvoices: build.query<
      DynamicResult<IGridOpenInvoices, { count: true }>,
      IGridOpenInvoicesArgs
    >({
      query: ({
        searchFirstName,
        searchLastName,
        date,
        take,
        skip,
        order,
        search,
        invoiceNumber,
        isActiveOnly,
      }) => ({
        url: API_USER_PHARMACIST_PROFILE_INVOICES.GET_ALL_DYNAMIC,
        params: {
          filter: mergeFilters(
            dynamic.makeFilter('isPaid', false, equals),
            isActiveOnly && dynamic.makeFilter('ignore', false, equals),
            dynamic.makeFilter('invoiceType.step', 2, equals),
            dynamic.makeFilter('userPharmacistProfileInvoiceDetails.count()', 0, more),
            dynamic.makeFilter(
              'userPharmacistProfile.firstName',
              searchFirstName,
              decoratorIsNotNullable(contains),
            ),
            dynamic.makeFilter(
              'userPharmacistProfile.lastName',
              searchLastName,
              decoratorIsNotNullable(contains),
            ),
            dynamic.makeFilter(
              ['poNumber', 'cardRemarks'],
              search,
              decoratorIsNotNullable(contains),
            ),
            dynamic.makeFilter('invoiceDate', date, decoratorIsNotNullable(dateRange)),
            dynamic.makeFilter('invoiceNumber', invoiceNumber, decoratorIsNotNullable(equals)),
          ).join('&&'),
          select: dynamic.select(
            'id',
            'userPharmacistProfileInvoiceDetails.sum(s=>s.quantity*s.amount) as totalAmount',
            'invoiceNumber',
            'invoiceDate',
            'poNumber',
            'userPharmacistProfile.fullName as name',
            'cardRemarks',
            'isSent',
            'invoiceURL',
            'userPharmacistProfileID',
            'userPharmacistProfileContact.name as contactName',
          ),
          count: true,
          take,
          skip,
          orderBy: dynamic.orderBy(order.field, order.order),
        },
      }),
    }),
    getGridUserPharmacistProfileInvoices: build.query<
      DynamicResult<IGridUserPharmacistProfileInvoices, { count: true }>,
      IGridUserPharmacistProfileInvoicesParams
    >({
      queryFn: async ({ skip, take, userPharmacistProfileID, search, orderBy, isActiveOnly }) => {
        try {
          const params = {
            select: dynamic.select(
              'id',
              'invoiceDate',
              'invoiceNumber',
              'userPharmacistProfileInvoiceDetails.sum(s=>s.amount * s.quantity) as totalAmount',
              'isActive',
              'paymentDate',
              'invoiceURL',
              'isPaid',
              'isSent',
              'userPharmacistProfileID',
              'invoiceTypeID',
              'invoiceType.title as invoiceTypeTitle',
              'invoiceType.paymentRequired as paymentRequired',
              'internalRemarks',
              'cardRemarks',
              'invoiceType.step as step',
              'userPharmacistProfileInvoiceDetails',
              'ignore',
              'userPharmacistProfileContact.name as contactName',
            ),
            filter: mergeFilters(
              isActiveOnly && dynamic.makeFilter('ignore', false, decoratorIsNotNullable(equals)),
              dynamic.makeFilter('invoiceNumber', search, decoratorIsNotNullable(contains)),
              dynamic.makeFilter('userPharmacistProfileID', userPharmacistProfileID, equals),
            ).join('&&'),
            orderBy: dynamic.orderBy(orderBy.field, orderBy.order),
            count: true,
            skip,
            take,
          };
          const { data } = await ServiceUserPharmacistProfileInvoices.getAllDynamic<
            IGridUserPharmacistProfileInvoices,
            typeof params
          >(params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getDashboardPaymentInvoices: build.query<
      IDashboardPaymentInvoices[],
      IDashboardPaymentInvoicesArgs
    >({
      query: ({ search, date, orderBy }) => ({
        url: API_USER_PHARMACIST_PROFILE_INVOICES.GET_ALL_DYNAMIC,
        params: {
          select: dynamic.select(
            'id',
            'paymentDate',
            'isPaid',
            'invoiceNumber',
            'invoiceURL',
            'userPharmacistProfile.fullName as paidBy',
            'userPharmacistProfileInvoiceDetails.sum(s=>s.quantity*s.amount) as totalAmount',
            'userPharmacistProfileID',
          ),
          filter: mergeFilters(
            dynamic.makeFilter(
              [
                'invoiceNumber',
                'userPharmacistProfile.firstName',
                'userPharmacistProfile.lastName',
              ],
              search,
              decoratorIsNotNullable(contains),
            ),
            dynamic.makeFilter('invoiceURL', null, notEquals),
            dynamic.makeFilter('paymentDate', date, decoratorIsNotNullable(dateRange)),
          ).join('&&'),
          orderBy: dynamic.orderBy(orderBy.field, orderBy.order),
        },
      }),
      transformResponse: transformResponseDynamic,
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getUserPharmacistProfileInvoices: build.query<UserPharmacistProfileInvoicesResponse, string>({
      queryFn: async (id) => {
        try {
          const params = {
            select: dynamic.select(
              'id',
              'invoiceNumber',
              'userPharmacistProfileID',
              'invoiceTypeID',
              'poNumber',
              'remarks',
              'userPharmacistProfileInvoiceDetails.count() as userPharmacistProfileInvoiceDetailsItemsCount',
              'isActive',
              'invoiceDate',
              'paymentDate',
              'isPaid',
              'isSent',
              'invoiceURL',
              'ignore',
              'internalRemarks',
              'cardRemarks',
              'userPharmacistProfileContact.name as contactName',
            ),
          };
          const { data } =
            await ServiceUserPharmacistProfileInvoices.getDynamic<UserPharmacistProfileInvoicesResponse>(
              id,
              params,
            );
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_TAG, id }],
    }),
    getUserPharmacistProfileInvoicesValidateExcel: build.query<
      IDashboardPaymentInvoices[],
      { excelUrl: string }
    >({
      queryFn: async ({ excelUrl }) => {
        try {
          const result = await apiApp.get(API_USER_PHARMACIST_PROFILE_INVOICES.VALIDATE_EXCEL, {
            params: {
              excelUrl,
            },
          });
          return result;
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    getMediaUpload: build.query<Required<MediaUpload>, { file: ValueFileUploaderFile }>({
      queryFn: async ({ file }) => {
        try {
          const result = await ServiceMediaUploads.uploadFile(file);
          return result;
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    uploadBulkInvoiceDetails: build.mutation<
      UserPharmacistProfileInvoiceDetailsExportToExcel,
      UserPharmacistProfileInvoiceDetailsExportToExcel
    >({
      queryFn: async (input) => {
        try {
          return await apiApp.post(
            API_USER_PHARMACIST_PROFILE_INVOICES.UPLOAD_BULK_INVOICE_DETAILS,
            input,
          );
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    postUserPharmacistProfileInvoices: build.mutation<
      UserPharmacistProfileInvoices,
      UserPharmacistProfileInvoices
    >({
      queryFn: async (customer) => {
        try {
          return await ServiceUserPharmacistProfileInvoices.post(customer);
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    patchUserPharmacistProfileInvoices: build.mutation<
      void,
      PatchPartial<UserPharmacistProfileInvoices, 'id'>
    >({
      queryFn: async (customer) => {
        try {
          await ServiceUserPharmacistProfileInvoices.patch(customer);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    deleteUserPharmacistProfileInvoices: build.mutation<
      void,
      PatchPartial<UserPharmacistProfileInvoices, 'id'>
    >({
      queryFn: async (customer) => {
        try {
          await ServiceUserPharmacistProfileInvoices.delete(customer);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),

    sendUserPharmacistProfileInvoices: build.mutation<void, UserPharmacistProfileInvoiceSendEmail>({
      query: (data) => ({
        url: API_USER_PHARMACIST_PROFILE_INVOICES.SEND_EMAIL,
        method: 'post',
        data,
      }),
    }),
    convertUserPharmacistProfileInvoices: build.mutation<
      Pick<UserPharmacistProfileInvoices, 'id' | 'isActive'>,
      ConvertUserPharmacistProfileInvoicesInput
    >({
      queryFn: async (input) => {
        try {
          return ServiceUserPharmacistProfileInvoices.convertInvoice(input);
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    createReceiptUserPharmacistProfileInvoices: build.mutation<
      Pick<UserPharmacistProfileInvoices, 'id' | 'isActive'>,
      CreateReceiptUserPharmacistProfileInvoicesInput
    >({
      queryFn: async (props) => {
        try {
          return ServiceUserPharmacistProfileInvoices.createReceipt(props);
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    ignoreAndDuplicateUserPharmacistProfileInvoices: build.mutation<
      Pick<UserPharmacistProfileInvoices, 'id' | 'isActive'>,
      IgnoreAndDuplicateUserPharmacistProfileInvoicesInput
    >({
      queryFn: async (input) => {
        try {
          return ServiceUserPharmacistProfileInvoices.ignoreAndDuplicateInvoice(input);
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
  }),
});
